<script setup>
import { ref, onBeforeMount } from "vue";
// import { useRouter } from "vue-router";
// import { useProfileStore } from "@/stores/profileStore";
import { useSalesOrderStore } from "@/stores/salesOrderStore";
// import { useCountryStore } from "@/stores/countryStore";
const loading = ref(false);
const submitted = ref(false);
// const router = useRouter();
// const paymentRequested = ref(false);
// const profileStore = useProfileStore();
const salesOrderStore = useSalesOrderStore();
// const countryStore = useCountryStore();
// const toggleBillingRecipient = () => {
//     salesOrderStore.toggleBillingRecipient();
// }
// const progressPercentage = computed(() => {
//     return parseInt((salesOrderStore.feedback.progress / 10) * 100);
// });
// const navigateToPayment = async () => {
//     paymentRequested.value = true;
//     let result = await salesOrderStore.submitOrder();
//     paymentRequested.value = false;
//     if (result === true && salesOrderStore.detail.stripePaymentIntentID) {
//         // console.log(profileStore.rootProtocol);
//         window.location = profileStore.rootProtocol + "//pay." + profileStore.rootDomain + "/payment/" + salesOrderStore.detail.stripePaymentIntentID;
//     }
// }
// const checks = () => salesOrderStore.checkBilling();
// watchEffect(() => checks());
onBeforeMount(async () => {
    loading.value = true;
    submitted.value = await salesOrderStore.submitOrder(true);
    loading.value = false;
});
</script>
<template>
    <div class="container orderReceived mt-4">
        <div v-if="loading" class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else-if="submitted === true" class="submit-success">
            <h3>Order Submitted</h3>
            <p>Thank you. We have received your order.</p>
            <p>Order ID # {{ salesOrderStore.detail.id }}</p>
            <p>Thank you for your inquiry a member of the team will come back to you shortly.</p>
        </div>
        <div v-else class="submit-error">
            <h3>Order Could Not Be Submitted</h3>
            <p>Sorry - something went wrong.</p>
            {{ submitted }}
        </div>

    </div>
</template>
<style scoped>

</style>
