<template>
    <div class="apostilleLegalisation-home">
        <h5>Step 2 | Job</h5>
        <label for="apostilleLegalisation.typeID">Job Type</label>
        <select v-model="apostilleLegalisationStore.typeID" id="apostilleLegalisation.typeID" class="form-control">
            <option v-for="jobType in apostilleLegalisationStore.jobTypes" :key="jobType.id" :value="jobType.id">{{ jobType.name }}</option>
        </select>
        <div v-if="apostilleLegalisationStore.typeID == 1 || apostilleLegalisationStore.typeID == 2" class="apostilleServiceLevel">
            <label for="apostilleLegalisation.apostilleServiceID">Apostille Service</label>
            <select v-model="apostilleLegalisationStore.apostilleServiceID" id="apostilleLegalisation.apostilleServiceID" class="form-control">
                <option value="1">Standard (2 working days)</option>
                <option value="2">Premium (1 working day)</option>
            </select>
        </div>
        <h5 class="mt-2">Documents to be Stamped | <button class="btn btn-dark btn-sm" @click="showAddDoc = !showAddDoc">&plus;</button></h5>
        <form v-if="showAddDoc" @submit.prevent="addToDocs" class="d-flex align-items-center">
            <label for="newDoc.qty">Quantity</label>
            <input type="number" v-model="newDoc.qty" id="newDoc.qty" class="form-control">
            <label for="newDoc.type">Type</label>
            <input type="text" v-model="newDoc.type" id="newDoc.type" class="form-control">
            <button class="btn btn-primary">Add</button>
        </form>
        <table class="table table-sm">
            <thead>
                <tr>
                    <th>Qty</th>
                    <th>Document</th>
                    <th>...</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(doc, index) in apostilleLegalisationStore.docs" :key="index">

                    <td>{{ doc.qty }}</td>
                    <td>{{ doc.type }}</td>

                </tr>
            </tbody>
        </table>
    </div>
</template>
<script setup>
    import { ref } from 'vue';
    import { useApostilleLegalisationStore } from '@/stores/apostilleLegalisationStore';
    const apostilleLegalisationStore = useApostilleLegalisationStore();
    const newDoc = ref({});
    const showAddDoc = ref(false);
    const addToDocs = () => {
        apostilleLegalisationStore.addToDocs(newDoc.value);
    }
    showAddDoc.value = false;

</script>