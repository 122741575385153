import { createRouter, createWebHistory } from "vue-router";

import HomeView from "../views/Guest/HomeView.vue";
import LoginView from "@/views/Guest/LoginView.vue";
import ReminderView from "@/views/Guest/ReminderView.vue";
import ActivateView from "@/views/Guest/ActivateView.vue";

import PasswordView from "@/views/User/PasswordView.vue";

import BirthCertificate from "@/views/System/BirthCertificate.vue";
import DeathCertificate from "@/views/System/DeathCertificate.vue";
import MarriageCertificate from "@/views/System/MarriageCertificate.vue";
import CivilCertificate from "@/views/System/CivilCertificate.vue";
import ApostilleLegalisation from "@/views/System/ApostilleLegalisation.vue";

import OrderDetails from "@/views/System/OrderDetails.vue";
import OrderReview from "@/views/System/OrderReview.vue";
import OrderBilling from "@/views/System/OrderBilling.vue";
import OrderSubmit from "@/views/System/OrderSubmit.vue";

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
    },
    {
        path: "/reminder",
        name: "reminder",
        component: ReminderView,
    },
    {
        path: "/activate/:email?/:code?",
        name: "activate",
        component: ActivateView,
    },
    {
        path: "/password",
        name: "password",
        component: PasswordView,
    },
    {
        path: "/birth-certificate/:id?",
        name: "BirthCertificate",
        component: BirthCertificate,
    },
    {
        path: "/death-certificate/:id?",
        name: "DeathCertificate",
        component: DeathCertificate,
    },
    {
        path: "/marriage-certificate/:id?",
        name: "MarriageCertificate",
        component: MarriageCertificate,
    },
    {
        path: "/civil-certificate/:id?",
        name: "CivilCertificate",
        component: CivilCertificate,
    },
    {
        path: "/apostille-and-legalisation",
        name: "ApostilleLegalisation",
        component: ApostilleLegalisation,
    },
    {
        path: "/order-review",
        name: "OrderReview",
        component: OrderReview,
    },
    {
        path: "/order-details",
        name: "OrderDetails",
        component: OrderDetails,
    },
    {
        path: "/order-billing",
        name: "OrderBilling",
        component: OrderBilling,
    },
    {
        path: "/order-submit",
        name: "OrderSubmit",
        component: OrderSubmit,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
});

export default router;
