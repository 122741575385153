<script setup>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useAlertStore } from "@/stores/alertStore";
import { useSalesOrderStore } from "@/stores/salesOrderStore";
// import { Tooltip } from "bootstrap";
import { useCertificatesStore } from "@/stores/certificatesStore";
import { useCountryStore } from "@/stores/countryStore";
const router = useRouter();
const salesOrderStore = useSalesOrderStore();
const alertStore = useAlertStore();
const certificatesStore = useCertificatesStore();
const countryStore = useCountryStore();
const loading = ref(false);
const editJob = (job) => {
    let certificate = certificatesStore.getCertificate(job.certificateID);
    // let thisJob = salesOrderStore.jobs[jobIndex];
    router.push('/' + certificate.url + "/" + job.id);
}
const deleteJob = async (jobID) => {
    const result = await salesOrderStore.deleteJob(jobID);
    if (result === true) {
        await salesOrderStore.fetchOrder();
        if (salesOrderStore.jobs.length == 0) router.replace('/');
    } else alertStore.add('warning', 'Could Not Delete', result);
}
// const submitForPayment = async () => {
//     console.log("Submit for Payment");
//     let result = await salesOrderStore.submitOrder();
//     if (result !== true) alertStore.add('warning','Could Not Submit Order',result);
// }
// const submitForPricing = async () => {
//     let result = salesOrderStore.submitOrder();
//     if (result !== true) {
//         alertStore.add('warning', 'Order Could Not Be Submitted', result);
//     } else {

//     }
// }
onBeforeMount(async () => {
    loading.value = true;
    let checks = await salesOrderStore.checkDetails();
    if (checks !== true) router.replace('/order-details');
    let result = await salesOrderStore.submitOrder();
    if (result !== true) {
        router.replace('/order-details');
        alertStore.add('warning', 'There was a problem', result);
    }
    if (salesOrderStore.jobs.length == 0) {
        router.replace('/');
    }
    loading.value = false;
}); 
</script>
<template>
    <div class="container orderReview mt-4">
        <h3>Order Review</h3>
        <div v-if="loading" class="spinner-grow text-success" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
        <div v-else class="order-review">
            <p>Thank you for your Order.</p>

            <p v-if="!salesOrderStore.detail.isProceedable" class="p-3 bg-warning rounded">Any legalisation order
                requests require pricing per application. A member of our team will respond back to you within 1 working
                day with a cost for approval.</p>
            <div class="table-responsive items-summary">
                <h6>Your Order Items</h6>
                <hr />
                <table class="table">
                    <thead>
                        <tr>
                            <th>Order Item</th>
                            <th class="text-end">...</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="job in salesOrderStore.jobs" :key="job.id">
                            <td>{{ job.name }}</td>
                            <td class="text-end">
                                <i class="bi bi-pencil-fill mx-1" @click="editJob(job)" title="Edit"></i>
                                <i class="bi bi-trash mx-1" @click="deleteJob(job.id)" title="Remove"></i>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Total</th>
                            <th class="text-end">
                                <span v-if="salesOrderStore.detail.isProceedable">
                                    {{ salesOrderStore.charges.totals.gross.toFixed(2) }}
                                </span>
                                <span v-else>
                                    TBA
                                </span>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <RouterLink class="btn btn-sm btn-danger rounded" to="/#certificate-type">Add Another
                                </RouterLink>
                            </th>
                            <th class="text-end">
                                <RouterLink v-if="salesOrderStore.detail.isProceedable" to="/order-billing"
                                    class="btn btn-sm btn-success rounded">Continue to Billing
                                </RouterLink>
                                <RouterLink v-else to="/order-submit" class="btn btn-sm btn-success rounded">Submit for
                                    Pricing
                                </RouterLink>
                            </th>
                            <th colspan="2">&nbsp;</th>
                        </tr>
                    </tfoot>
                </table>

            </div>

            <div class="table-responsive details-summary">
                <h6>Your Order Instructions | <RouterLink to="/order-details" style="color: black; font-size:.9em;"><i
                            class="bi bi-pencil-fill" title="Edit"></i></RouterLink>
                </h6>
                <hr />
            </div>
            <table class="table">
                <tbody>
                    <tr>
                        <th>ID</th>
                        <td># {{ salesOrderStore.detail.id }}</td>
                    </tr>
                    <tr>
                        <th>Service</th>
                        <td>{{ salesOrderStore.serviceName }}</td>
                    </tr>
                    <tr>
                        <th>Certificate Service</th>
                        <td>{{ salesOrderStore.certificatePriority(salesOrderStore.detail.certificatePriorityID) }}</td>
                    </tr>
                    <tr v-if="salesOrderStore.detail.apostillePriorityID">
                        <th>Apostille Priority</th>
                        <td>{{ salesOrderStore.apostillePriority(salesOrderStore.detail.apostillePriorityID) }}</td>
                    </tr>
                    <tr v-if="salesOrderStore.detail.legalisationEmbassyID">
                        <th>Embassy</th>
                        <td>{{ countryStore.countryName(salesOrderStore.detail.legalisationEmbassyID) }}</td>
                    </tr>
                    <tr>
                        <th>Return Method</th>
                        <td>{{ salesOrderStore.despatchName.name }}</td>
                    </tr>
                    <tr>
                        <th>Destination</th>
                        <td>
                            {{ salesOrderStore.detail.recipientPostcode }},
                            {{ countryStore.countryName(salesOrderStore.detail.recipientCountryID) }}
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>
<style scoped>
i {
    cursor: pointer;
}
</style>