import { defineStore } from "pinia";
// import { useProfileStore } from "@/stores/profileStore";

export const useCivilCertificateStore = defineStore("civilCertificateStore", {
    state: () => ({
        cert: {},
        feedback: {},
        certificateID: 4,
        name: 'Civil Marriage Certificate',
        url: 'civil-marriage-certificate',
        title: null,
        requiredFields: 14,
        percentComplete: 0
    }),

    actions: {
        async checks() {
            this.feedback = {};
            this.feedback.progress = 0;
            this.cert.certificateID = this.certificateID;
            if (!this.cert || this.cert.certificateID) this.cert.certificateID = this.certificateID;
            this.cert.title = this.name + " | " + this.cert.forenames + " " + this.cert.surname;
            this.cert.url = this.url;

            if (!this.cert.year) {
                this.feedback.message = "Please complete the year the Formation was registered";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (isNaN(this.cert.year)) {
                this.feedback.message = "The year the Formation was registered must be a numeric value";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.year = true;

            if (!this.cert.dob) {
                this.feedback.message = "Please complete the Date of Formation";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.dob = true;


            if (!this.cert.place) {
                this.feedback.message = "Please complete the  Place of Formation";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.place = true;


            if (!this.cert.surname) {
                this.feedback.message = "Please complete the Surname for Partner 1";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.cert.surname.length < 2) {
                this.feedback.message = "Partner 1 Surname must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.surname = true;

            if (!this.cert.forenames) {
                this.feedback.message = "Please complete the Partner 1 forenames";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.cert.forenames.length < 2) {
                this.feedback.message = "Partner 1 forenames must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.forenames = true;

            if (!this.cert.partner1Address) {
                this.feedback.message = "Please complete the Address for Partner 1";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.partner1Address = true;

            if (!this.cert.party2Surname) {
                this.feedback.message = "Please complete the Surname for Party 2";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.cert.party2Surname.length < 2) {
                this.feedback.message = "Party 2 Surname must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.party2Surname = true;

            if (!this.cert.party2Forenames) {
                this.feedback.message = "Please complete the forenames for Party 2";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.cert.party2Forenames.length < 2) {
                this.feedback.message = "Party 2 forenames must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.party2Forenames = true;

            if (!this.cert.partner2Address) {
                this.feedback.message = "Please complete the Address for Partner 2";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.partner2Address = true;

        },
    },
});
