<template>
    <header>
        <div class="header-wrapper">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-3 col-sm-3 col-12 logo order-3 order-lg-1">
                        <a href="/"><img src="@/assets/images/cdn-logo.svg" alt="logo"></a>
                    </div>
                    <div class="col-lg-6 col-sm-8 col-12 order-3 order-lg-2">
                        <h2>Express Documents</h2>
                    </div>
                    <div class="col-lg-3 col-12 links order-1 order-lg-3">
                        <div class="links-wrapper">
                            <p class="title">Contact the team</p>
                            <div class="contact-items">
                                <div class="contact-item">
                                    <a href="tel:+443334567100"><img src="@/assets/images/phone.svg" alt="phone" />0333
                                        4567 100</a>
                                </div>
                                <div class="contact-item">
                                    <a href="mailto:legal@cdn-consular.co.uk"><img src="@/assets/images/email.svg"
                                            alt="phone" />legal@cdn-consular.co.uk</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>