import { defineStore } from "pinia";
// import { useProfileStore } from "@/stores/profileStore";

export const useBirthCertificateStore = defineStore("birthCertificateStore", {
    state: () => ({
        birth: {},
        feedback: {},
        certificateID: 1,
        name: 'Birth Certificate',
        url: 'birth-certificate',
        title: null,
        requiredFields: 14,
        percentComplete: 0
    }),

    actions: {
        async checks() {
            this.feedback = {};
            this.feedback.progress = 0;
            this.birth.certificateID = this.certificateID;
            const date = new Date();
            if (!this.birth || this.birth.certificateID) this.birth.certificateID = this.certificateID;
            this.birth.title = this.name + " | " + this.birth.forenames + " " + this.birth.surname;
            this.birth.url = this.url;
            if (!this.birth.year) {
                this.feedback.message = "Please complete the year of birth";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (isNaN(this.birth.year)) {
                this.feedback.message = "Birth year must be a numeric value";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            if (this.birth.year < 1900 || this.birth.year > date.getFullYear()) {
                this.feedback.message = "Birth year must be between 1900 and " + date.getFullYear();
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.year = true;

            if (!this.birth.surname) {
                this.feedback.message = "Please complete the Surname";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.birth.surname.length < 2) {
                this.feedback.message = "Surname must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.surname = true;

            if (!this.birth.forenames) {
                this.feedback.message = "Please complete the Forename";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.birth.forenames.length < 2) {
                this.feedback.message = "Forename must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.forenames = true;

            if (!this.birth.dob) {
                this.feedback.message = "Please complete the Date of Birth";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.dob = true;

            if (!this.birth.place) {
                this.feedback.message = "Please complete the Place of Birth";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.place = true;

            if (!this.birth.fatherSurname) {
                this.feedback.message = "Please complete the Father's Surname";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.fatherSurname = true;

            if (!this.birth.fatherForenames) {
                this.feedback.message = "Please complete the Father's Forename(s)";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.fatherForenames = true;

            if (!this.birth.motherMaidenName) {
                this.feedback.message = "Please complete the Mother's Maiden Name";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.motherMaidenName = true;

            if (!this.birth.motherSurname) {
                this.feedback.message = "Please complete the Mother's SurName";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.motherSurname = true;

            if (!this.birth.motherForenames) {
                this.feedback.message = "Please complete the Mother's Forename(s)";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.motherForenames = true;
            return true;
        },
        validateEmail(email) {
            return email.match(/^(([^<>()[\]\\.,;:\s@]+(\.[^<>()[\]\\.,;:\s@]+)*)|(.+))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        },
    },
});
