import { defineStore } from "pinia";

export const useProfileStore = defineStore("profileStore", {
    state: () => ({
        system: {
            name: "CDN Express Documents",
        },
        jwt: null,
        auth: {},
        remind: {},
        registration: {},
        activation: {},
        changePassword: {},
        profile: {},
        cookie: {
            path: "/",
            domain: null,
        },
        reference: {},
        logging: [],
        captureLogging: false,
    }),
    getters: {
        rootDomain() {
            // const localDomains = [
            //     'localhost',
            //     'app.expressdocument.local'
            // ];
            // for (let l in localDomains) {
            //     if (location.host.substring(0, localDomains[l].length) == localDomains[l]) return "expressdocument.local";
            // }
            // return (location.host.substring(0, 9) == "localhost" || location.host.substring(0, 25) == "app.expressdocument.local") ? "expressdocument.local" : "";
            return "expressdocument.co.uk";
        },
        rootProtocol() {
            return 'https';
            // return location.protocol;
        },
        rootApi() {
            return 'https://api.expressdocument.co.uk';
            // return this.rootProtocol + "//api." + this.rootDomain;
        },
    },
    actions: {
        loggingAdd(text) {
            if (this.captureLogging === true) this.logging.push(text);
        },
        checkJWT() {
            const url = this.rootApi + "/user/profile";
            fetch(url, {
                method: "get",
                headers: new Headers({
                    Authorization: "Bearer " + this.jwt,
                }),
            })
                .then((response) => response.json())
                .then((data) => console.log(data));
        },
        async login() {
            const url = this.rootApi + "/guest/login";
            let status;
            try {
                let response = await fetch(url, {
                    method: "POST",
                    body: JSON.stringify(this.auth),
                });
                status = response.status;
                if (status == 200) {
                    this.loggingAdd("Login Success");
                    this.jwt = await response.json();
                    this.loggingAdd(this.jwt);
                    this.storeJWT(this.jwt);
                    this.fetchProfile();
                }
            } catch (err) {
                console.log(err);
            }
        },
        async logout() {
            const url = this.rootApi + "/user/logout";
            this.logging = [];
            try {
                await fetch(url, {
                    method: "get",
                    headers: new Headers({
                        Authorization: "Bearer " + this.jwt,
                    }),
                });
            } catch (err) {
                console.error(err);
            }
            this.jwt = null;
            this.profile = {};
            this.storeJWT(this.jwt);
        },
        async register() {
            const url = this.rootApi + "/guest/register";
            let status;
            try {
                let response = await fetch(url, {
                    method: "post",
                    body: JSON.stringify(this.registration),
                });
                status = response.status;
                if (status != 200) {
                    return await response.json();
                }
                this.activation.email = this.registration.email;
                this.registration = {};
                return true;
            } catch (err) {
                console.log(err);
            }
        },
        async activate() {
            const url = this.rootApi + "/guest/activate/" + this.activation.email + "/" + this.activation.code;
            let status;
            try {
                let response = await fetch(url);
                status = response.status;
                if (status == 200) {
                    this.jwt = await response.json();
                    this.storeJWT(this.jwt);
                    this.fetchProfile();
                }
            } catch (err) {
                console.log(err);
            }
        },
        async reminder() {
            const url = this.rootApi + "/guest/reminder";
            let status;
            try {
                let response = await fetch(url, {
                    method: "post",
                    body: JSON.stringify(this.remind),
                });
                status = response.status;
                if (status != 200) {
                    return await response.json();
                }
                return true;
            } catch (err) {
                console.log(err);
            }
        },
        async savePassword() {
            const url = this.rootApi + "/user/password";
            try {
                let response = await fetch(url, {
                    headers: new Headers({
                        Authorization: "Bearer " + this.jwt,
                    }),
                    method: "post",
                    body: JSON.stringify(this.changePassword),
                });
                if (response.status != 200) return await response.json();
                this.changePassword = {};
                return true;
            } catch (err) {
                console.log(err);
            }
        },
        async fetchProfile() {
            this.profile = {};
            if (!this.jwt) return;
            const url = this.rootApi + "/user/profile";
            this.loggingAdd(url);
            let headers = new Headers({
                Authorization: "Bearer " + this.jwt,
            });
            this.loggingAdd(headers);
            let response = await fetch(url, {
                method: "get",
                headers: headers,
            });
            if (response.status == 200) this.profile = await response.json();
            this.fetchReferenceData();
        },
        saveProfile() {
            const url = this.rootApi + "/user/profile";
            const method = "patch";
            let status;
            fetch(url, {
                method: method,
                headers: new Headers({
                    Authorization: "Bearer " + this.jwt,
                }),
                body: JSON.stringify(this.profile),
            })
                .then((response) => {
                    status = response.status;
                })
                .catch((err) => console.error("Status", status, "Error", err));
        },
        fetchJwt() {
            if (this.jwt) return this.jwt;
            let cname = "jwt";
            let name = cname + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(";");
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == " ") {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    var rlt = c.substring(name.length, c.length);
                    this.jwt = rlt;
                    return rlt;
                }
            }
        },
        fetchReferenceData() {
            let url = this.rootApi + "/reference";
            fetch(url, {
                method: "get",
                headers: new Headers({
                    Authorization: "Bearer " + this.jwt,
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => (this.reference = data))
                .catch((err) => console.error(err));
        },
        storeJWT(jwt) {
            console.log("Storing", jwt);
            let expires;
            if (!jwt) {
                expires = "Thu, 01 Jan 1970 00:00:01 GMT";
            } else {
                let now = new Date();
                let time = now.getTime();
                let expireTime = time + 1000 * 72000;
                now.setTime(expireTime);
                expires = now.toUTCString();
            }
            var cookieTxt = "jwt=" + jwt + ";path=" + this.cookie.path + ";";
            if (this.cookie.domain) cookieTxt += "domain=" + this.cookie.domain + ";";
            cookieTxt += "expires=" + expires;
            console.log(cookieTxt);
            document.cookie = cookieTxt;
        },
    },
});
