<script setup>
import { ref, onBeforeMount, watchEffect, computed } from "vue";
// import { useRouter } from "vue-router";
import { useProfileStore } from "@/stores/profileStore";
import { useSalesOrderStore } from "@/stores/salesOrderStore";
import { useCountryStore } from "@/stores/countryStore";
// const router = useRouter();
const paymentRequested = ref(false);
const profileStore = useProfileStore();
const salesOrderStore = useSalesOrderStore();
const countryStore = useCountryStore();
const toggleBillingRecipient = () => {
    salesOrderStore.toggleBillingRecipient();
}
const progressPercentage = computed(() => {
    return parseInt((salesOrderStore.feedback.progress / 10) * 100);
});
const navigateToPayment = async () => {
    paymentRequested.value = true;
    let result = await salesOrderStore.submitOrder();
    paymentRequested.value = false;
    if (result === true && salesOrderStore.detail.stripePaymentIntentID) {
        // console.log(profileStore.rootProtocol);
        window.location = profileStore.rootProtocol + "://pay." + profileStore.rootDomain + "/payment/" + salesOrderStore.detail.stripePaymentIntentID;
    }
}
const checks = () => salesOrderStore.checkBilling();
watchEffect(() => checks());
onBeforeMount(() => checks());
</script>
<template>
    <div class="container birthCertificate mt-4">
        <h3>Order Details</h3>

        <div class="billAnother">
            <p v-if="salesOrderStore.feedback.message" class="p-2 text-white rounded"
                :class="'bg-' + salesOrderStore.feedback.level">{{ salesOrderStore.feedback.message }}</p>
            <div class="progress">
                <div class="progress-bar" role="progressbar" aria-label="Example with label"
                    :style="'width: ' + progressPercentage + '%;'" :aria-valuenow="salesOrderStore.feedback.progress"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ progressPercentage }}%
                </div>
            </div>
            <form class="mt-2" disabled>
                <h5>Your Billing Information |
                    <button class="btn btn-xs btn-warning ms-1 rounded" @click.prevent="toggleBillingRecipient">
                        <span v-if="salesOrderStore.billRecipient">
                            Bill Another Person
                        </span>
                        <span v-else>
                            Bill Recipient
                        </span>
                    </button>
                </h5>

                <label for="detail.billingName">Your Name</label>
                <fieldset :disabled="salesOrderStore.billRecipient">
                    <input type="text" v-model="salesOrderStore.detail.billingName" id="detail.billingName"
                        class="form-control" placeholder="Contact Name" required autocomplete="name" />
                    <i v-if="salesOrderStore.feedback.billingName" class="bi bi-check-circle-fill"></i>
                </fieldset>

                <label for="detail.billingEmail">Email Address</label>
                <fieldset :disabled="salesOrderStore.billRecipient">
                    <input type="email" v-model="salesOrderStore.detail.billingEmail" id="detail.billingEmail"
                        class="form-control"
                        :class="{ 'border-danger': salesOrderStore.feedbackAlert == 'billingEmail' }"
                        placeholder="Contact Email" required
                        autocomplete="email" />
                    <i v-if="salesOrderStore.feedback.billingEmail" class="bi bi-check-circle-fill"></i>
                    <i v-else-if="salesOrderStore.feedbackAlert == 'billingEmail'"
                        class="bi bi-exclamation-triangle-fill text-danger"></i>
                </fieldset>

                <label for="detail.billingTelephone">Telephone</label>
                <fieldset :disabled="salesOrderStore.billRecipient">
                    <input type="tel" v-model="salesOrderStore.detail.billingTelephone" id="detail.billingTelephone"
                        class="form-control" placeholder="Contact Telephone" required />
                    <i v-if="salesOrderStore.feedback.billingTelephone" class="bi bi-check-circle-fill"></i>
                </fieldset>

                <label for="detail.billingLine1">Address Line 1</label>
                <fieldset :disabled="salesOrderStore.billRecipient">
                    <input type="text" v-model="salesOrderStore.detail.billingLine1" id="detail.billingLine1"
                        class="form-control" placeholder="Contact Address Line 1" required
                        autocomplete="address-line1" />
                    <i v-if="salesOrderStore.feedback.billingLine1" class="bi bi-check-circle-fill"></i>
                </fieldset>

                <label for="detail.billingLine2">Address Line 2</label>
                <fieldset :disabled="salesOrderStore.billRecipient">
                    <input type="text" v-model="salesOrderStore.detail.billingLine2" id="detail.billingLine2"
                        class="form-control" placeholder="Contact Address Line 2" autocomplete="address-line2" />
                    <i v-if="salesOrderStore.feedback.billingLine2" class="bi bi-check-circle-fill"></i>
                </fieldset>

                <label for="detail.billingLine3">Address Line 3</label>
                <fieldset :disabled="salesOrderStore.billRecipient">
                    <input type="text" v-model="salesOrderStore.detail.billingLine3" id="detail.billingLine3"
                        class="form-control" placeholder="Address Line 3" autocomplete="address-line3" />
                    <i v-if="salesOrderStore.feedback.billingLine3" class="bi bi-check-circle-fill"></i>
                </fieldset>

                <label for="detail.billingTown">Town</label>
                <fieldset :disabled="salesOrderStore.billRecipient">
                    <input type="text" v-model="salesOrderStore.detail.billingTown" id="detail.billingTown"
                        class="form-control" placeholder="Town" required autocomplete="address-level2" />
                    <i v-if="salesOrderStore.feedback.billingTown" class="bi bi-check-circle-fill"></i>
                </fieldset>

                <label for="detail.billingCounty">County</label>
                <fieldset :disabled="salesOrderStore.billRecipient">
                    <input type="text" v-model="salesOrderStore.detail.billingCounty" id="detail.billingCounty"
                        class="form-control" placeholder="County" autocomplete="address-level3" />
                    <i v-if="salesOrderStore.feedback.billingCounty" class="bi bi-check-circle-fill"></i>
                </fieldset>

                <label for="detail.billingPostcode">Postcode</label>
                <fieldset :disabled="salesOrderStore.billRecipient">
                    <input type="text" v-model="salesOrderStore.detail.billingPostcode" id="detail.billingPostcode"
                        class="form-control" placeholder="Postcode" autocomplete="postal-code" />
                    <i v-if="salesOrderStore.feedback.billingPostcode" class="bi bi-check-circle-fill"></i>
                </fieldset>

                <label for="detail.billingCountryID">Country</label>
                <fieldset :disabled="salesOrderStore.billRecipient">
                    <select v-model="salesOrderStore.detail.billingCountryID" id="detail.billingCountryID"
                        class="form-control">
                        <option v-for="country in countryStore.countries" :value="country.id" :key="country.id">{{
                            country.name
                        }}</option>
                    </select>
                    <i v-if="salesOrderStore.feedback.billingCountryID" class="bi bi-check-circle-fill"></i>
                </fieldset>
            </form>
        </div>

        <div class="d-flex align-items-center justify-content-between mt-2">
            <RouterLink to="/order-review" class="btn btn-warning rounded">Back</RouterLink>
            <button class="btn btn-success rounded submitForPaymentBtn"
                :disabled="paymentRequested === true || progressPercentage !== 100"
                @click.prevent="navigateToPayment">
                <div v-if="paymentRequested" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span v-else>Pay Now</span>
            </button>
        </div>



    </div>
</template>
<style scoped>
fieldset {
    position: relative;
    display: flex;
    align-items: center;
}

fieldset i {
    position: absolute;
    right: 10px;
    color: green;
    padding: 2px;
    font-size: 1.5em;
}

.submitForPaymentBtn {
    width: 110px;
}
</style>
