<script setup>
import { useRoute } from 'vue-router';
import Alerts from '@/components/user-alerts';
import { useCountryStore } from "@/stores/countryStore";
import { useSalesOrderStore } from "@/stores/salesOrderStore";
import { onBeforeMount } from '@vue/runtime-core';
import PublicHeader from '@/Navs/public-header.vue';
import GuestNav from '@/Navs/guest-nav.vue';
import PublicFooter from '@/components/public-footer.vue';
import FooterSection from '@/components/FooterSection.vue';
const route = useRoute();
const country = useCountryStore();
const salesOrderStore = useSalesOrderStore();
onBeforeMount(() => {
    country.fetchCountries();
    salesOrderStore.getPricing();
})

</script>

<template>
    <div id="page">
        <PublicHeader v-if="!route.path || route.path == '/'" />
        <GuestNav v-else />
        <main style="min-height: 75vh;">
            <router-view />
            <Alerts />
        </main>
        <PublicFooter v-if="!route.path || route.path == '/'" />
        <FooterSection v-else />
    </div>
</template>


<style lang="scss">
@import "./node_modules/bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

body {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

.btn-xs {
    padding: 0.25em 1em !important;
}

.nav-link {
    cursor: pointer;
}

footer {
    background-color: #444;
    color: white;
    padding: 2rem;
}

/* HTML */
h1,
h2,
h3,
h4,
h5,
h6,
li,
a,
p,
button,
input,
span,
label,
select,
option,
address,
table {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1,
h2,
h3 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html {
    overflow-y: scroll;
}


a {
    color: initial;
}


p a {
    border-bottom: 1px solid #0D6990;
    padding-bottom: 2px;
}

p a:hover,
a:hover {
    opacity: .9;
}

ul,
ol {
    padding-left: 16px;
}

ol {
    margin: 0;
}

ul li {
    list-style: disc;
}

ol li {
    list-style: decimal;
}

button,
input,
select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    outline: none;
}

a:hover {
    text-decoration: none;
}

body {
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: #fff;
    color: #212121;
}

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
}

body::-webkit-scrollbar {
    width: 6px;
    background-color: #fff;
}

body::-webkit-scrollbar-thumb {
    background-color: #aaa;
}

h2 {
    font-size: 30px;
    font-weight: 300;
}


p {
    font-weight: 400;
    font-size: 16px;
    color: #212121;
    line-height: 1.5;
}

li {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
    color: #333333;
    margin-bottom: 15px;
}

.main-content li {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.7;
}

ul {
    margin: 0;
}

img {
    max-width: 100%;
}

/* Max Bootstrap width override */
@media (min-width: 992px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 960px !important;
    }
}




header .header-wrapper {
    background-color: #F31212;
    padding: 25px 40px;
}

header .header-wrapper h2 {
    text-align: center;
    color: #fff;
    font-size: 42px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
}

header .header-wrapper .links {
    text-align: right;
}

header .header-wrapper .links-wrapper {
    display: inline-block;
    text-align: left;
}

header .header-wrapper .links .contact-items div:last-child {
    margin-bottom: 0;
}

header .header-wrapper .links .contact-item {
    margin-bottom: 10px;
}

header .header-wrapper .links p,
header .header-wrapper .links a {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
}

header .header-wrapper .links img {
    display: inline-block;
    margin-right: 10px;
    width: 22px;
}

header .header-wrapper .links .title {
    font-size: 21px;
    font-weight: 700;
    margin-bottom: 5px;
}


.site-main .hero {
    position: relative;
}


.site-main .hero .image-wrapper {
    position: absolute;
    overflow: hidden;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
}

.site-main .hero .image-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

}



.site-main .hero .image-wrapper img.fade-out {
    opacity: 0;
    -moz-transition: opacity 1s ease;
    -ms-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
}

.site-main .hero .image-wrapper img.fade-in {
    opacity: 1;
    -moz-transition: opacity 1s ease;
    -ms-transition: opacity 1s ease;
    -o-transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
}

.site-main .hero .image-wrapper .cached {
    visibility: hidden;
    overflow: hidden;
}

.site-main .hero .hero-content {
    z-index: 9;
    position: relative;
}

.site-main .hero .hero-content .hero-content-wrapper {
    padding: 35px;
    background-color: #fff;
    margin: 80px 0;
}

.site-main .hero .hero-content .hero-content-wrapper h2 {
    color: #F31212;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.site-main .hero .hero-content .hero-content-wrapper .title {
    font-weight: 700;
    font-size: 30px;
    color: #212121;
    line-height: 1.2;
    margin-bottom: 10px;
}

.site-main .hero .hero-content .hero-content-wrapper p.detail {
    font-size: 18px;
    max-width: 330px;
}

.site-main section.intro {
    padding: 80px 0;
}

.site-main section.intro h1 {
    font-size: 30px;
    font-weight: 700;
    max-width: 500px;
    margin-bottom: 30px;
}

.site-main section.intro p {
    max-width: 485px;
}

.site-main section.intro .actions {
    margin-top: 25px;
}

.site-main section.intro ul {
    list-style: none;
    padding: 0;
}

.site-main section.intro ul li {
    display: flex;
    align-items: start;
    margin-bottom: 40px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.3;
}

.site-main section.intro ul li span {
    margin-right: 15px;
    width: 30px;
    flex-shrink: 0;
}

.site-main section.intro ul li span img {
    width: 26px;
}

.site-main section.grey {
    background-color: #FAFAFA;
}

.site-main section.benefits {
    padding: 80px 0;
}

.site-main section.benefits .tile {
    margin-bottom: 30px;
    box-shadow: 14px 14px 40px #00000019;
    border-radius: 30px;
    height: 100%;
}

.site-main section.benefits .tile-inner {
    padding: 50px;
    padding-top: 20px;
    background-color: #fff;
    border-radius: 30px;
    height: 100%;

    display: flex;
    flex-direction: column;
}

.site-main section.benefits .tile-inner .actions-section {
    margin-top: auto;
}

.site-main section.benefits .tile-inner .image {
    max-width: 230px;
    margin-bottom: 10px;
    height: 180px;
}

.site-main section.benefits .tile-inner .title h3 {
    font-size: 25px;
    font-weight: 400;
    color: #F31212;
    margin-bottom: 20px;
}

.site-main section.benefits .tile-inner .content li {
    margin-bottom: 5px;
    line-height: 1.2;
}

.site-main section.benefits .tile-inner .content .actions {
    margin-top: 30px;
}

.site-main section.certificate {
    padding: 80px 0;
    position: relative;
}

.site-main section.certificate .certificate-anchor {
    position: absolute;
    top: 0px;
}

.site-main section.certificate h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 50px;
}

.site-main section.certificate .certificates {
    max-width: 680px;
}

.site-main section.certificate .certificates a {
    border-radius: 100px;
    overflow: hidden;
    display: inline-block;
    width: 325px;
    padding: 15px 20px;
    background-color: #F31212;
    text-align: center;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 25px;
}

.site-main section.certificate .certificates a:hover {
    background-color: #d50505;
}

.site-main section.certificate .certificates a:active,
.site-main section.certificate .certificates a:focus {
    background-color: #b10505 !important;
}

.site-main section.certificate .questions {
    border-left: 12px solid #F31212;
    margin-top: 60px;
    padding: 30px 20px 30px 40px;
    background-color: #FAFAFA;
}

.site-main section.certificate .questions .questions-statement p {
    margin-bottom: 0;
    font-size: 30px;
    color: #212121;
    font-weight: 700;
    line-height: 1.2;
    max-width: 80%;
}

.site-main section.certificate .questions .contact-details p {
    margin-bottom: 0;
    font-size: 23px;
}

.site-main section.certificate .questions .contact-details p a {
    border: 0;
    color: #F31212;
    font-weight: 700;
    text-decoration: none;
}

.site-footer {
    background-color: #F31212;
    padding: 20px 30px;
}

.site-footer .company-details {
    display: flex;
    align-items: start;
    justify-content: left;
}

.site-footer .company-details .address {
    margin-left: 30px;
}

.site-footer .company-details .address address {
    margin-bottom: 0;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
}

.site-footer .links {
    color: #fff;
    display: flex;
    align-items: end;
    justify-content: right;
}

.site-footer .links a {
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    padding: 0 5px;
}



.btn {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border: 0;
    padding: 14px 20px;
}

.btn-primary {
    background-color: #F31212;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
}

.btn-primary:hover {
    background-color: #d50505;
    color: #fff;
}

.btn-primary:focus,
.btn-primary:active {
    background-color: #b10505 !important;
    color: #fff;
}

@media only screen and (max-width: 1199.98px) {

    header .header-wrapper {
        padding: 25px 30px;
    }

    header .header-wrapper .links p,
    header .header-wrapper .links a {
        font-size: 15px;
    }

    header .header-wrapper h2 {
        font-size: 31px;
    }


}

/* 1199px End */


@media only screen and (min-width: 992px) and (max-width: 1100.98px) {

    header .header-wrapper .links p,
    header .header-wrapper .links a {
        font-size: 14px;
    }
}

@media only screen and (max-width: 991.98px) {

    header .header-wrapper .links {
        text-align: left;
        border-bottom: 1px solid #fff;
        margin-bottom: 15px;
    }

    header .header-wrapper .links .links-wrapper .title {
        display: none;
    }

    header .header-wrapper .links .links-wrapper .contact-item {
        margin-bottom: 10px;
        margin-right: 20px;
        display: inline-block;
    }

    header .header-wrapper h2 {
        text-align: left;
        position: relative;
        top: 10px;
    }

    .site-main section.certificate .questions .contact-details p {
        margin-bottom: 0;
        font-size: 19px;
    }



}

/* 991 End */

@media only screen and (max-width: 767.98px) {
    header .header-wrapper h2 {
        font-size: 27px;
    }

    .site-main .intro .steps {
        margin-top: 50px;
    }

    .site-main section.benefits .tile-inner {
        padding: 40px;
        padding-top: 20px;
    }

    .site-main section.benefits .tile {
        height: auto;
    }

    .site-main section.certificate .certificates a {
        width: 100%;
        padding: 10px 20px;
    }

    .site-main section.certificate .certificates>.row>div:last-of-type a {
        margin-bottom: 0;
    }

    .site-main section.certificate .questions .questions-statement p {
        max-width: 100%;
        margin-bottom: 30px;
    }

    .site-main section.certificate .questions .contact-details p {
        font-size: 24px;
    }

    .site-footer .links {
        justify-content: start;
        margin-left: 168px;
        margin-top: 20px;
    }

    .site-footer .company-details .logo {
        width: 142px;
    }

    .site-main section.benefits .tile-inner .actions-section {
        margin-top: 30px;
    }



}

/* 767 end */

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 575.98px) {
    header .header-wrapper h2 {
        font-size: 23px;
    }

    header .header-wrapper {
        padding: 10px 10px 20px;
    }

    .site-main section.intro {
        padding: 60px 0;
    }

    .site-main section.benefits {
        padding: 60px 0;
    }

    .site-main section.certificate {
        padding: 60px 0;
    }

    header .header-wrapper .logo img {
        max-width: 90px;
    }

    header .header-wrapper .logo {
        text-align: center;
    }

    header .header-wrapper h2 {
        text-align: center;
        padding: 10px 0;
    }

    header .header-wrapper .links {
        text-align: center;
    }




}

/* 575 end */


@media only screen and (max-width: 480.98px) {

    header .header-wrapper .links p,
    header .header-wrapper .links a {
        font-size: 13px;
    }

    header .header-wrapper .links img {
        margin-right: 4px;
        width: 12px;
        position: relative;
        top: -1px;
    }

    header .header-wrapper .links .links-wrapper .contact-item {
        margin-bottom: 10px;
        margin-right: 10px;
    }

    header .header-wrapper .links .links-wrapper .contact-item:last-of-type {
        margin-right: 0;
    }



    .site-main .hero .hero-content .hero-content-wrapper .title {
        font-size: 26px;
    }

    .site-main .hero .hero-content .hero-content-wrapper p.detail {
        font-size: 17px;
    }

    .site-main section.intro h1 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .site-main section.intro ul li {
        font-size: 21px;
    }

    .site-main section.intro ul li:last-of-type {
        margin-bottom: 0;
    }

    .site-main section.benefits .tile-inner .image {
        max-width: 183px;
        height: auto;
    }

    .site-main section.certificate .questions .contact-details p {
        font-size: 17px;
    }

    .site-main section.certificate h2 {
        font-size: 25px;
    }

    .site-footer {
        padding: 20px 10px;
    }

    .site-footer .company-details .address {
        margin-left: 15px;
    }

    .site-footer .links {
        justify-content: start;
        margin-left: 110px;
    }

    .site-footer .company-details .logo {
        width: 100px;
        flex-shrink: 0;
    }



    .container {
        padding-left: 25px;
        padding-right: 25px;
    }




}

/* 480 end */
</style>