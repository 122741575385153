import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";
import { createPinia } from 'pinia'

import "bootstrap"
import 'bootstrap-icons/font/bootstrap-icons.css'


const pinia = createPinia()

const app = createApp(App)

app.use(pinia)
app.use(router)
app.use(VueGtag, {
    config: { id: "G-SK1GX8YN4J" }
})
app.mount('#app')