<script setup>
import { onBeforeMount, watchEffect, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useBirthCertificateStore } from "@/stores/birthCertificateStore";
import { useSalesOrderStore } from "@/stores/salesOrderStore";

const route = useRoute();
const router = useRouter();
const birthCertificate = useBirthCertificateStore();
const salesOrderStore = useSalesOrderStore();
const progressPercentage = computed(() => {
    return parseInt((birthCertificate.feedback.progress / birthCertificate.requiredFields) * 100);
});
const checks = () => {
    return birthCertificate.checks();
};
const progressToOrder = async () => {
    addJob();
    let checks = await salesOrderStore.checkDetails();
    console.log("Checks result", checks, "Job Count", salesOrderStore.jobs.length);
    (checks !== true) ? router.push("/order-details") : router.push("/order-review");
};
const addAnotherJob = () => {
    addJob();
    router.push("/");
};
const addJob = () => {
    if (birthCertificate.birth.id) {
        salesOrderStore.updateJob(birthCertificate.birth);
    } else salesOrderStore.jobs.push(birthCertificate.birth);
    birthCertificate.birth = {};
}
watchEffect(() => {
    checks();
});

onBeforeMount(() => {
    if (route.params.id) birthCertificate.birth = salesOrderStore.getJob(route.params.id);
    checks();
});
</script>
<template>
    <div class="container birthCertificate mt-4">
        <h3>Birth Certificate</h3>
        <p v-if="birthCertificate.feedback.message" class="p-2 text-white"
            :class="'bg-' + birthCertificate.feedback.level">
            {{ birthCertificate.feedback.message }}
        </p>
        <div class="progress">
            <div class="progress-bar" role="progressbar" aria-label="Progress"
                :style="'width: ' + progressPercentage + '%;'" :aria-valuenow="birthCertificate.feedback.progress"
                aria-valuemin="0" aria-valuemax="100">
                {{ progressPercentage }}%
            </div>
        </div>
        <form>
            <label for="birthCertificate.birth.year">Year Birth was Registered</label>
            <fieldset>
                <input type="text" v-model="birthCertificate.birth.year" id="birthCertificate.birth.year"
                    class="form-control" />
                <i v-if="birthCertificate.feedback.year" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="birthCertificate.birth.surname">Surname at Birth</label>
            <fieldset>
                <input type="text" v-model="birthCertificate.birth.surname" id="birthCertificate.birth.year"
                    class="form-control" />
                <i v-if="birthCertificate.feedback.surname" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="birthCertificate.birth.forenames">Forename(s)</label>
            <fieldset>
                <input type="text" v-model="birthCertificate.birth.forenames" id="birthCertificate.birth.forenames"
                    class="form-control" />
                <i v-if="birthCertificate.feedback.forenames" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="birthCertificate.birth.dob">Date of Birth</label>
            <fieldset>
                <input type="date" v-model="birthCertificate.birth.dob" id="birthCertificate.birth.dob"
                    class="form-control" />
                <i v-if="birthCertificate.feedback.dob" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="birthCertificate.birth.place">Place of Birth</label>
            <fieldset>
                <input type="text" v-model="birthCertificate.birth.place" id="birthCertificate.birth.place"
                    class="form-control" />
                <i v-if="birthCertificate.feedback.place" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="birthCertificate.birth.fatherSurname">Father/Parent Surname</label>
            <fieldset>
                <input type="fatherSurname" v-model="birthCertificate.birth.fatherSurname"
                    id="birthCertificate.birth.fatherSurname" class="form-control" />
                <i v-if="birthCertificate.feedback.fatherSurname" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="birthCertificate.birth.fatherForenames">Father/Parent Forename(s)</label>
            <fieldset>
                <input type="text" v-model="birthCertificate.birth.fatherForenames"
                    id="birthCertificate.birth.fatherForenames" class="form-control" />
                <i v-if="birthCertificate.feedback.fatherForenames" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="birthCertificate.birth.motherMaidenName">Mother's Maiden Name</label>
            <fieldset>
                <input type="text" v-model="birthCertificate.birth.motherMaidenName"
                    id="birthCertificate.birth.motherMaidenName" class="form-control" />
                <i v-if="birthCertificate.feedback.motherMaidenName" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="birthCertificate.birth.motherSurname">Mother's Surname at time of Birth</label>
            <fieldset>
                <input type="text" v-model="birthCertificate.birth.motherSurname"
                    id="birthCertificate.birth.motherSurname" class="form-control" />
                <i v-if="birthCertificate.feedback.motherSurname" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="birthCertificate.birth.motherForenames">Mother's Forename(s)</label>
            <fieldset>
                <input type="text" v-model="birthCertificate.birth.motherForenames"
                    id="birthCertificate.birth.motherForenames" class="form-control" />
                <i v-if="birthCertificate.feedback.motherForenames" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <div class="d-flex justify-content-between">
                <button class="btn btn-sm btn-danger mt-2 rounded" :disabled="progressPercentage != 100"
                    @click.prevent="addAnotherJob">Another</button>

                <button class="btn btn-sm btn-success mt-2 rounded" :disabled="progressPercentage != 100"
                    @click.prevent="progressToOrder">Next</button>
            </div>
        </form>
    </div>
</template>
<style scoped>
fieldset {
    position: relative;
    display: flex;
    align-items: center;
}

fieldset i {
    position: absolute;
    right: 10px;
    color: green;
    padding: 2px;
    font-size: 1.5em;
}
</style>
