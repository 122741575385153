<script setup>

import { useProfileStore } from "@/stores/profileStore";
import { useSalesOrderStore } from "@/stores/salesOrderStore";
const profile = useProfileStore();
const salesOrderStore = useSalesOrderStore();

</script>

<template>
    <nav class="navbar navbar-expand-sm  navbar-dark bg-dark">
        <div class="container-fluid">
            <router-link to="/" class="navbar-brand">
                <!-- <img src="" :alt="profile.system.name" height="50" class="rounded-circle" /> -->
                <b class="ms-2">{{ profile.system.name }}</b>
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                    <router-link class="nav-link" to="/" title="Home"><i class="bi bi-house"></i> Home</router-link>

                    <router-link class="nav-link position-relative" to="/order-review" title="My Order"
                        v-if="salesOrderStore.jobs.length > 0 && salesOrderStore.proceedToReview === true">
                        <span class="position-absolute top-10 start-100 translate-middle badge rounded-pill bg-danger">
                            {{ salesOrderStore.jobs.length }}
                            <span class="visually-hidden">certificates on your order</span>
                        </span>
                        <i class="bi bi-cart"> My Order</i>
                    </router-link>

                </ul>
            </div>
        </div>
    </nav>
</template>

