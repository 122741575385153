import { defineStore } from 'pinia'

export const useApostilleLegalisationStore = defineStore('apostilleLegalisationmStore', {
    state: () => ({
        jobTypes: [
            {id: 1, name: 'Apostille Only'},
            {id: 2, name: 'Apostille & Legalisation'},
            {id: 3, name: 'Legalisation Only'},
        ],
        maritalStatuses: [
            {id: 1, name: 'Married'},
            {id: 2, name: 'Divorced'},
            {id: 3, name: 'Widowed'},
            {id: 4, name: 'Single'},
        ],
        stage: 1,
        checkStage: 1,
        qty: 1,
        docs: [],
        applicant: {},
        recipient: {},
    }),
    actions: {
        checks() {
            this.checkStage = 1;
            // For Check Stage 1
            if (!this.applicant.name) return 'Applicant Name';
            if (!this.applicant.line1) return 'Applicant Address Line 1';
            if (!this.applicant.town) return 'Applicant Town';
            if (!this.applicant.postcode) return 'Applicant Postcode';
            if (!this.applicant.telephone) return 'Applicant Telephone';
            this.checkStage++;
            // For Check Stage 2
        },
        apostilleLegalisationApplicantAsRecipient() {
            this.recipient = {...this.applicant}
        },
        addToDocs (docObject) {
            this.docs.push(docObject);
        }
    },
})