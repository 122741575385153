import { defineStore } from 'pinia'
import { useProfileStore } from "@/stores/profileStore";

export const useCountryStore = defineStore('countryStore', {
    state: () => ({
        countries: [],
    }),
    getters: {
        hagueCountries() {
            let countries = [];
            countries = this.countries.filter((country) => country.isHague == 1)
            return countries;
        },
        nonHagueCountries() {
            let countries = [];
            countries = this.countries.filter((country) => country.isHague != 1)
            return countries;
        },
        legalisationCountries() {
            let countries;
            countries = this.countries.filter((country) => country.acceptsLegalisation == 1)
            return countries;

        }
    },
    actions: {
        countryName(countryID) {
            for (let c in this.countries) {
                if (this.countries[c].id == countryID) return this.countries[c].name;
            }
        },
        async fetchCountries() {
            const profileStore = useProfileStore();
            const url = profileStore.rootApi + "/guest/countries";
            // const url = 'https://api.expressdocument.co.uk/guest/countries';
            const response = await fetch(url, {
                method: 'GET', // or 'POST' etc.
                headers: {
                    'Content-Type': 'application/json',
                    'Origin': 'http://localhost:8000',
                    // You don't manually set 'Origin', browsers do that
                },
                mode: 'cors' // this needs to be set for CORS requests
            });
            const data = await response.json();
            if (response.status !== 200) return data;
            this.countries = data;
            return true;
        }
    }
})