import { defineStore } from "pinia";
// import { useProfileStore } from "@/stores/profileStore";

export const useMarriageCertificateStore = defineStore("marriageCertificateStore", {
    state: () => ({
        cert: {},
        feedback: {},
        certificateID: 3,
        name: 'Marriage Certificate',
        url: 'marriage-certificate',
        title: null,
        requiredFields: 16,
        percentComplete: 0
    }),

    actions: {
        async checks() {
            this.feedback = {};
            this.feedback.progress = 0;
            this.cert.certificateID = this.certificateID;
            if (!this.cert || this.cert.certificateID) this.cert.certificateID = this.certificateID;
            this.cert.title = this.name + " | " + this.cert.forenames + " " + this.cert.surname;
            this.cert.url = this.url;
            if (!this.cert.year) {
                this.feedback.message = "Please complete the year the marriage was registered";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (isNaN(this.cert.year)) {
                this.feedback.message = "The year the marriage was registered must be a numeric value";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.year = true;

            if (!this.cert.surname) {
                this.feedback.message = "Please complete the Party 1 Surname";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.cert.surname.length < 2) {
                this.feedback.message = "Party 1 Surname must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.surname = true;

            if (!this.cert.forenames) {
                this.feedback.message = "Please complete the Party 1 forenames";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.cert.forenames.length < 2) {
                this.feedback.message = "Party 1 forenames must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.forenames = true;

            if (!this.cert.party2Surname) {
                this.feedback.message = "Please complete the Surname for Party 2";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.cert.party2Surname.length < 2) {
                this.feedback.message = "Party 2 Surname must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.party2Surname = true;

            if (!this.cert.party2Forenames) {
                this.feedback.message = "Please complete the forenames for Party 2";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.cert.party2Forenames.length < 2) {
                this.feedback.message = "Party 2 forenames must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.party2Forenames = true;

            if (!this.cert.dob) {
                this.feedback.message = "Please complete the Marriage Date";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.dob = true;

            if (!this.cert.place) {
                this.feedback.message = "Please complete the Place of Marriage";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.place = true;

            if (!this.cert.fatherSurname) {
                this.feedback.message = "Please complete Parent's Surname for Party 1";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.fatherSurname = true;

            if (!this.cert.fatherForenames) {
                this.feedback.message = "Please complete Parent's Forenames for Party 1";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.fatherForenames = true;

            if (!this.cert.motherSurname) {
                this.feedback.message = "Please complete Parent's Surname for Party 1";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.motherSurname = true;

            if (!this.cert.motherForenames) {
                this.feedback.message = "Please complete Parent's Forenames for Party 1";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.motherForenames = true;
        },
    },
});
