import { defineStore } from "pinia";
// import { useProfileStore } from "@/stores/profileStore";

export const useDeathCertificateStore = defineStore("deathCertificateStore", {
    state: () => ({
        cert: {},
        feedback: {},
        certificateID: 2,
        name: 'Death Certificate',
        url: 'death-certificate',
        title: null,
        requiredFields: 10,
        percentComplete: 0
    }),

    actions: {
        async checks() {
            this.feedback = {};
            this.feedback.progress = 0;
            this.cert.certificateID = this.certificateID;
            if (!this.cert || this.cert.certificateID) this.cert.certificateID = this.certificateID;
            this.cert.title = this.name + " | " + this.cert.forenames + " " + this.cert.surname;
            this.cert.url = this.url;
            if (!this.cert.year) {
                this.feedback.message = "Please complete the year of death";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (isNaN(this.cert.year)) {
                this.feedback.message = "Birth year must be a numeric value";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.year = true;

            if (!this.cert.surname) {
                this.feedback.message = "Please complete the Surname";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.cert.surname.length < 2) {
                this.feedback.message = "Surname must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.surname = true;

            if (!this.cert.forenames) {
                this.feedback.message = "Please complete the forenames";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            if (this.cert.forenames.length < 2) {
                this.feedback.message = "Surname must be at least 2 characters";
                this.feedback.level = "danger";
                return;
            } else this.feedback.progress++;
            this.feedback.forenames = true;

            if (!this.cert.dob) {
                this.feedback.message = "Please complete the Date of Death";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.dob = true;

            if (!this.cert.age) {
                this.feedback.message = "Please complete the Age at Death";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.age = true;

            if (!this.cert.place) {
                this.feedback.message = "Please complete the Place of Death";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.place = true;

            if (!this.cert.occupation) {
                this.feedback.message = "Please complete the Deceased's Occupation";
                this.feedback.level = "warning";
                return;
            } else this.feedback.progress++;
            this.feedback.occupation = true;

            // if (!this.cert.maritalStatus) {
            //     this.feedback.message = "Please complete the Deceased's Marital Status";
            //     this.feedback.level = "warning";
            //     return;
            // } else this.feedback.progress++;
            // this.feedback.maritalStatus = true;
        },
    },
});
