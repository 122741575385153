<template>
    <div class="activate container">
        <h1 class="mt-4">Activate</h1>
        <form @submit.prevent="activate" class="form-signin">
            <label for="profile.activation.email" class="sr-only">Email address</label>
            <input type="email" id="profile.activation.email" v-model="profile.activation.email" class="form-control"
                placeholder="Email address" required autofocus />
            <label for="profile.activation.code" class="sr-only">Code</label>
            <input type="number" id="profile.activation.code" v-model="profile.activation.code" class="form-control"
                placeholder="Code" required />
            <button id="activateBtn" class="btn btn-primary btn-block mt-2" type="submit" :disabled="loading">
                <div v-if="loading" class="spinner-border spinner-border-sm text-light"
                    style="width: 1.5rem; height: 1.5rem" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <span v-else>Activate</span>
            </button>
        </form>
        <p class="mt-4">
            Not arrived? Click <router-link to="/reminder">here</router-link> to request again.
        </p>
    </div>
</template>
<script setup>
import { onBeforeMount } from '@vue/runtime-core';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useProfileStore } from "@/stores/profileStore";
import { useAlertStore } from "@/stores/alertStore";

const loading = ref(false);
const router = useRouter();
const route = useRoute();
const profile = useProfileStore();
const alerts = useAlertStore();

const activate = async () => {
    loading.value = true;
    await profile.activate();
    if (profile.jwt) {
        alerts.add('success', 'Success', 'You are activated and logged in');
        alerts.add('info', 'Hint', 'Change your password now');
        router.push("/dashboard");
    } else {
        alerts.add('danger', 'Oops', 'Activation failed. Try again or use the Password Reminder option');
        loading.value = false;
    }
}

onBeforeMount(() => {
    if (route.params.email) profile.activation.email = route.params.email;
    if (route.params.code) profile.activation.code = route.params.code;
    if (profile.activation.email && profile.activation.code) activate();
})
</script>