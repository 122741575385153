<template>
    <div class="container marriageCertificate mt-4">
        <h3>Apostille and Legalisation</h3>
        <component v-if="apostilleLegalisationStore.stage == 1" :is="ApostilleLegalisationHome"></component>
        <component v-if="apostilleLegalisationStore.stage == 2" :is="ApostilleLegalisationJob"></component>
        <component v-if="apostilleLegalisationStore.stage == 3" :is="ApostilleLegalisationRecipient"></component>
        <component v-if="apostilleLegalisationStore.stage == 4" :is="ApostilleLegalisationReview"></component>

        <div class="buttons mt-4">
            <button v-if="apostilleLegalisationStore.stage == 2" class="btn btn-sm btn-outline-dark me-1" @click="apostilleLegalisationStore.stage = 1">
                <span>&lt; </span>
                    Applicant
            </button>

            <button v-if="apostilleLegalisationStore.stage == 1 || apostilleLegalisationStore.stage == 3" class="btn btn-sm btn-outline-dark me-1" @click="apostilleLegalisationStore.stage = 2">
                <span v-if="apostilleLegalisationStore.stage == 3">&lt; </span>
                Job
                <span v-if="apostilleLegalisationStore.stage == 1">&gt; </span>
            </button>

            <button v-if="apostilleLegalisationStore.stage == 2 || apostilleLegalisationStore.stage == 4" class="btn btn-sm btn-outline-dark me-1" @click="apostilleLegalisationStore.stage = 3">
                <span v-if="apostilleLegalisationStore.stage == 4">&lt; </span>
                Recipient
                <span v-if="apostilleLegalisationStore.stage == 2">&gt; </span>
            </button>

            <button v-if="apostilleLegalisationStore.stage == 3" class="btn btn-sm btn-outline-dark me-1" @click="apostilleLegalisationStore.stage = 4">
                Review
                <span>&gt; </span>
            </button>
        </div>
    </div>
</template>
<script setup>
    import ApostilleLegalisationHome from '@/components/ApostilleLegalisation/ApostilleLegalisation-Home.vue';
    import ApostilleLegalisationJob from '@/components/ApostilleLegalisation/ApostilleLegalisation-Job.vue';
    import ApostilleLegalisationRecipient from '@/components/ApostilleLegalisation/ApostilleLegalisation-Recipient.vue';
    import ApostilleLegalisationReview from '@/components/ApostilleLegalisation/ApostilleLegalisation-Review.vue';
    import { useApostilleLegalisationStore } from '@/stores/apostilleLegalisationStore';
    const apostilleLegalisationStore = useApostilleLegalisationStore();
</script>