import { defineStore } from 'pinia'

export const useCertificatesStore = defineStore('certificatesStore', {
    state: () => ({
        certificates: [
            {id: 1, name: 'Birth Certificate', 'url': 'birth-certificate'},
            {id: 2, name: 'Death Certificate', 'url': 'death-certificate'},
            {id: 3, name: 'Marriage Certificate', 'url': 'marriage-certificate'},
            {id: 4, name: 'Civil Partnership Certificate', 'url': 'civil-certificate'},
        ],
    }),
    getters: {
    },
    actions: {
        getCertificate(certificateID) {
            for (let c in this.certificates) {
                if (this.certificates[c].id == certificateID) return this.certificates[c];
            }
        }
    },
})