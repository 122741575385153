<template>
    <div class="apostilleLegalisation-home">
        <h5>Step 4 | Review</h5>
        <div class="table-responsive">
            <table class="table table-sm">
                <tbody>
                    <tr>
                        <th>Applicant Name</th>
                        <td>{{ apostilleLegalisationStore.applicant.name }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
</div>
</template>
<script setup>
    import { useApostilleLegalisationStore } from '@/stores/apostilleLegalisationStore';
    const apostilleLegalisationStore = useApostilleLegalisationStore();
</script>