<script setup>
import { onBeforeMount, watchEffect, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useDeathCertificateStore } from "@/stores/deathCertificateStore";
import { useSalesOrderStore } from "@/stores/salesOrderStore";

const route = useRoute();
const router = useRouter();
const certificate = useDeathCertificateStore();
const salesOrderStore = useSalesOrderStore();
const progressPercentage = computed(() => {
    return parseInt((certificate.feedback.progress / certificate.requiredFields) * 100);
});
const checks = () => {
    certificate.checks();
};
const progressToOrder = async () => {
    await salesOrderStore.addJob(certificate.cert);
    certificate.cert = {};
    let checks = await salesOrderStore.checkDetails();
    (salesOrderStore.jobs.length == 1 || checks !== true) ? router.push("/order-details") : router.push("/order-review");
};
const addAnotherJob = async () => {
    await salesOrderStore.addJob(certificate.cert);
    certificate.cert = {};
    router.push("/");
};

// const addJob = () => {
//     if (certificate.cert.id) {
//         salesOrderStore.updateJob(certificate.cert);
//     } else salesOrderStore.jobs.push(certificate.cert);
//     certificate.cert = {};
// }

watchEffect(() => {
    checks();
});

onBeforeMount(() => {
    if (route.params.id) {
        certificate.cert = salesOrderStore.getJob(route.params.id);
    }
    checks();
});
</script>
<template>
    <div class="container deathCertificate mt-4">
        <h3>Death Certificate</h3>
        <p v-if="certificate.feedback.message" class="p-2 text-white"
            :class="'bg-' + certificate.feedback.level">
            {{ certificate.feedback.message }}
        </p>
        <div class="progress">
            <div class="progress-bar" role="progressbar" aria-label="Progress"
                :style="'width: ' + progressPercentage + '%;'" :aria-valuenow="certificate.feedback.progress"
                aria-valuemin="0" aria-valuemax="100">
                {{ progressPercentage }}%
            </div>
        </div>
        <form>
            <label for="cert.year">Year Death was Registered</label>
            <fieldset>
                <input type="number" v-model="certificate.cert.year" id="cert.year" class="form-control">
                <i v-if="certificate.feedback.year" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.surname">Surname of Deceased</label>
            <fieldset>
                <input type="text" v-model="certificate.cert.surname" id="cert.surname" class="form-control">
                <i v-if="certificate.feedback.surname" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.forenames">Forename(s) of Deceased</label>
            <fieldset>
                <input type="text" v-model="certificate.cert.forenames" id="cert.forenames" class="form-control">
                <i v-if="certificate.feedback.forenames" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.dob">Date of Death</label>
            <fieldset>
                <input type="date" v-model="certificate.cert.dob" id="cert.dob" class="form-control">
                <i v-if="certificate.feedback.dob" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.age">Age at Death</label>
            <fieldset>
                <input type="number" v-model="certificate.cert.age" id="cert.age" class="form-control">
                <i v-if="certificate.feedback.age" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.place">Place of Death</label>
            <fieldset>
                <input type="text" v-model="certificate.cert.place" id="cert.place" class="form-control">
                <i v-if="certificate.feedback.place" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.occupation">Occupation of Deceased</label>
            <fieldset>
                <input type="text" v-model="certificate.cert.occupation" id="cert.occupation" class="form-control">
                <i v-if="certificate.feedback.occupation" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.maritalStatus">Marital Status of Deceased (if female)</label>
            <fieldset>
                <select v-model="certificate.cert.maritalStatus" id="cert.maritalStatus" class="form-control">
                    <option v-for="maritalStatus in salesOrderStore.maritalStatuses" :key="maritalStatus.id"
                        :value="maritalStatus.id">{{ maritalStatus.name }}</option>
                </select>
                <i v-if="certificate.feedback.maritalStatus" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <div class="d-flex justify-content-between">
                <button class="btn btn-danger mt-2" :disabled="progressPercentage != 100"
                    @click.prevent="addAnotherJob">Another</button>

                <button class="btn btn-success mt-2" :disabled="progressPercentage != 100"
                    @click.prevent="progressToOrder">Next</button>
            </div>
        </form>
    </div>
</template>
<style scoped>
fieldset {
    position: relative;
    display: flex;
    align-items: center;
}

fieldset i {
    position: absolute;
    right: 10px;
    color: green;
    padding: 2px;
    font-size: 1.5em;
}
</style>