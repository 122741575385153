<template>
    <div class="apostilleLegalisation-home">
        <h5>Step 3 | Recipient | Recipient of Documents</h5>
        <button class="btn btn-sm btn-dark" @click="apostilleLegalisationStore.apostilleLegalisationApplicantAsRecipient">Use My Details</button>
        <form class="mt-2">
            <label for="apostilleLegalisation.recipientName">Name</label>
            <input type="text" v-model="apostilleLegalisationStore.recipient.name" id="apostilleLegalisation.recipientName" class="form-control" />
    
            <label for="apostilleLegalisation.recipientCompany">Company Name</label>
            <input type="text" v-model="apostilleLegalisationStore.recipient.company" id="apostilleLegalisation.recipientCompany" class="form-control" />
    
            <label for="apostilleLegalisation.recipientLine1">Address Line 1</label>
            <input type="text" v-model="apostilleLegalisationStore.recipient.line1" id="apostilleLegalisation.recipientLine1" class="form-control" />
    
            <label for="apostilleLegalisation.recipientTown">Town</label>
            <input type="text" v-model="apostilleLegalisationStore.recipient.town" id="apostilleLegalisation.recipientTown" class="form-control" />
    
            <label for="apostilleLegalisation.recipientPostcode">Postcode</label>
            <input type="text" v-model="apostilleLegalisationStore.recipient.postcode" id="apostilleLegalisation.recipientPostcode" class="form-control" />
    
            <label for="apostilleLegalisation.recipientCountry">Country</label>
            <select v-model="apostilleLegalisationStore.recipient.country" id="apostilleLegalisation.recipientCountry" class="form-control">
            </select>
    
            <label for="apostilleLegalisation.recipientTelephone">Telephone</label>
            <input type="tel" v-model="apostilleLegalisationStore.recipient.telephone" id="apostilleLegalisation.recipientTelephone" class="form-control" />
        </form>

</div>
</template>
<script setup>
    import { useApostilleLegalisationStore } from '@/stores/apostilleLegalisationStore';
    const apostilleLegalisationStore = useApostilleLegalisationStore();
</script>