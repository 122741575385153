<script setup>
import { ref } from 'vue';
const heroCounter = ref(1);
setInterval(() => {
    heroCounter.value++;
    if (heroCounter.value > 2) heroCounter.value = 0;
}, 5000)
//     image.classList.add('fade-out');
//     nextImage.classList.add('fade-in');
// const fadeHeroImages = () => {
// hero.value.img = "/assets/images/marriage-certificate.jpg";
// hero.value.img = "/assets/images/birth-certificate.jpg";
// return;
// let images = [
//     "/assets/images/birth-certificate.jpg",
//     "/assets/images/marriage-certificate.jpg",
//     "/assets/images/certificate1.jpg"
// ];
// let index = 0;
// let image = document.querySelector('.image-wrapper img.current');
// let nextImage = document.querySelector('.image-wrapper img.next');
// // let interval = setInterval(changeImage, 5000);
// setInterval(changeImage, 5000);

// //Display block for next image
// nextImage.style.display = "block";

// function changeImage() {
//     index++;
//     if (index >= images.length) index = 0;

//     //Swap images
//     nextImage.src = images[index];
//     image.classList.add('fade-out');
//     nextImage.classList.add('fade-in');

//     //Offset image swap from animation to prevent flicker
//     setTimeout(function () {
//         image.src = nextImage.src;
//     }, 1000);
//     setTimeout(function () {
//         image.classList.remove('fade-out');
//         nextImage.classList.remove('fade-in');
//     }, 1500);
// }
// }
// onMounted(() => fadeHeroImages())
</script>

<template>
    <div class="site-main">
        <div class="hero">
            <!-- Fade between two images -->
            <div class="image-wrapper">
                <img :class="[heroCounter == 1 ? 'current fade-in' : 'next fade-out']"
                    src="@/assets/images/birth-certificate.jpg"
                    alt="Order a Birth Certificate">
                <img :class="[heroCounter == 2 ? 'current fade-in' : 'next fade-out']"
                    src="@/assets/images/marriage-certificate.jpg"
                    alt="Order a Marriage Certificate">
                <img :class="[heroCounter == 0 ? 'current fade-in' : 'next fade-out']"
                    src="@/assets/images/certificate1.jpg" alt="hero.alt">
            </div>

            <div class="hero-content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-sm-10 col-auto">
                            <div class="hero-content-wrapper">
                                <h2>Gro Certificate & Legalisation</h2>
                                <p class="title">Need a Certificate Fast?</p>
                                <p class="detail">Birth/Marriage/Death/Civil certificates from <strong>£30.25</strong>
                                </p>
                                <div class="actions">
                                    <a class="btn btn-primary" href="#certificate-type">Get Started Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section class="intro">
            <div class="container">
                <div class="row">
                    <div class="col-lg-7 col-md-6 col-12">
                        <h1>Our fast and simple service allows you to order a Birth, <wbr>Marriage, <wbr>Death<wbr>, Civil Certificate in minutes from as low as £30.25.
                        </h1>
                        <p>We use our own in-house couriers exclusively to obtain your apostille and embassy
                            legalisations (if required) as quickly as possible. All orders can be returned to an address
                            in the UK or internationally using trusted couriers.</p>
                        <div class="actions">
                            <a class="btn btn-primary" href="#certificate-type">Order Now</a>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-5 offset-md-1 col-12 steps">
                        <ul>
                            <li><span><img src="@/assets/images/checkmark.svg" alt="check"></span>Simply complete the
                                online order form</li>
                            <li><span><img src="@/assets/images/checkmark.svg" alt="check"></span>We arrange certificate
                                and any legalisation requirements if required</li>
                            <li><span><img src="@/assets/images/checkmark.svg" alt="check"></span>The completed order is
                                sent to destination of your choice</li>
                        </ul>
                    </div>

                </div>
            </div>
        </section>

        <section class="benefits grey">
            <div class="container">
                <div class="row row g-5">
                    <div class="col-md-6 col-12">
                        <div class="tile">
                            <div class="tile-inner">
                                <div class="main-section">
                                    <div class="image">
                                        <img src="@/assets/images/documents-covered.png" alt="documents covered">
                                    </div>
                                    <div class="title">
                                        <h3>Documents covered</h3>
                                    </div>
                                    <div class="content">
                                        <ul>
                                            <li>Birth certificate</li>
                                            <li>Marriage certificate</li>
                                            <li>Death certificate</li>
                                            <li>Civil Partnership certificate</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="actions-section">
                                    <div class="actions">
                                        <a class="btn btn-primary" href="#certificate-type">Order Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-12">
                        <div class="tile">
                            <div class="tile-inner">
                                <div class="main-section">
                                    <div class="image">
                                        <img src="@/assets/images/reasons-required.png" alt="reasons required">
                                    </div>
                                    <div class="title">
                                        <h3>Reasons required</h3>
                                    </div>
                                    <div class="content">
                                        <ul>
                                            <li>To assist with Visa applications</li>
                                            <li>Register overseas Birth</li>
                                            <li>Finalise probate issues for overseas use</li>
                                            <li>Apply for passport</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="actions-section">
                                    <div class="actions">
                                        <a class="btn btn-primary" href="#certificate-type">Order Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-12">
                        <div class="tile">
                            <div class="tile-inner">
                                <div class="main-section">
                                    <div class="image">
                                        <img src="@/assets/images/quick-turnaround.png" alt="quick turnaround">
                                    </div>
                                    <div class="title">
                                        <h3>Quick turnaround</h3>
                                    </div>
                                    <div class="content">
                                        <span><strong>Service Available</strong></span>
                                        <ul>
                                            <li>Standard & Priority Certificate application</li>
                                            <li>Standard & Premium Apostille available</li>
                                            <li>Embassy service, all fees paid on your behalf</li>
                                            <li>Special delivery post or Express International Courier</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="actions-section">
                                    <div class="actions">
                                        <a class="btn btn-primary" href="#certificate-type">Order Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 col-12">
                        <div class="tile">
                            <div class="tile-inner">
                                <div class="main-section">
                                    <div class="image">
                                        <img src="@/assets/images/dedicated-courier-service.png"
                                            alt="dedicated courier service">
                                    </div>
                                    <div class="title">
                                        <h3>Dedicated courier service</h3>
                                    </div>
                                    <div class="content">
                                        <p>Using our own couriers and established international couriers, we can also
                                            despatch overseas for a flat fee providing you with all tracking details</p>
                                    </div>
                                </div>
                                <div class="actions-section">
                                    <div class="actions">
                                        <a class="btn btn-primary" href="#certificate-type">Order Now</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="certificate">
            <a name="certificate-type" id="certificate-type" class="certificate-anchor"></a>
            <div class="container">
                <div class="col-12">
                    <h2>Select your certificate type</h2>
                    <div class="certificates">
                        <div class="row">
                            <div class="col-md-6 col-12">
                                <RouterLink to="/birth-certificate">Birth</RouterLink>
                            </div>
                            <div class="col-md-6 col-12">
                                <RouterLink to="/marriage-certificate">Marriage</RouterLink>
                            </div>
                            <div class="col-md-6 col-12">
                                <RouterLink to="/death-certificate">Death</RouterLink>
                            </div>
                            <div class="col-md-6 col-12">
                                <RouterLink to="/civil-certificate">Civil
                                    Partnership</RouterLink>
                            </div>
                        </div>
                    </div>

                    <div class="questions">
                        <div class="row align-items-center">
                            <div class="col-md-6 col-12 questions-statement">
                                <p>Have a question or unsure of the process?</p>
                            </div>
                            <div class="col-md-6 col-12 contact-details">
                                <p>call us on <a href="tel:+443334567100">0333 4567 100</a><br>email <a
                                        href="mailto:legal@cdn-consular.co.uk">legal@cdn-consular.co.uk</a><br> to
                                    discuss your requirements</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style>
.optionIcons {
    margin-top: 2em;
    display: flex;
    flex-wrap: wrap;
}

.optionIcon {
    border: thin solid #ccc;
    padding: 1em;
    border-radius: .5em;
    margin: 1em;
    box-shadow: 5px 5px 5px #ccc;
    width: 200px;
}
</style>
