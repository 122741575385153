<template>
    <div class="apostilleLegalisation-home">
        <h5>Step 1 | About You | Applicant</h5>
        <label for="apostilleLegalisation.applicantName">Full Name</label>
        <input type="text" v-model="apostilleLegalisationStore.applicant.name" id="apostilleLegalisation.applicantName" class="form-control">

        <label for="apostilleLegalisation.applicantLine1">Street Address</label>
        <input type="text" v-model="apostilleLegalisationStore.applicant.line1" id="apostilleLegalisation.applicantLine1" class="form-control">

        <label for="apostilleLegalisation.applicantTown">Town</label>
        <input type="text" v-model="apostilleLegalisationStore.applicant.town" id="apostilleLegalisation.applicantTown" class="form-control">

        <label for="apostilleLegalisation.applicantPostcode">Postcode</label>
        <input type="text" v-model="apostilleLegalisationStore.applicant.postcode" id="apostilleLegalisation.applicantPostcode" class="form-control">

        <label for="apostilleLegalisation.applicantCountry">Country</label>
        <select v-model="apostilleLegalisationStore.applicant.country" id="apostilleLegalisation.applicantCountry" class="form-control">
        </select>

        <label for="apostilleLegalisation.applicantTelephone">Telephone</label>
        <input type="tel" v-model="apostilleLegalisationStore.applicant.telephone" id="apostilleLegalisation.applicantTelephone" class="form-control">

        <label for="apostilleLegalisation.applicantEmail">Email</label>
        <input type="email" v-model="apostilleLegalisationStore.applicant.email" id="apostilleLegalisation.email" class="form-control">

        
</div>
</template>
<script setup>
    import { useApostilleLegalisationStore } from '@/stores/apostilleLegalisationStore';
    const apostilleLegalisationStore = useApostilleLegalisationStore();
</script>