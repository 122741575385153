<template>
    <div class="site-footer">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 col-12 company-details">
                    <div class="logo">
                        <a href="/">
                            <img src="@/assets/images/cdn-logo.svg" alt="CDN Consular">
                        </a>
                    </div>
                    <div class="address">
                        <address>
                            CDN Consular Services Limited<br>
                            Heritage House<br>
                            Church Road<br>
                            Egham Surrey<br>
                            TW20 9QD
                        </address>
                    </div>
                </div>
                <div class="col-md-6 col-12 links">
                    <div class="link-wrapper">
                        <a href="https://static1.squarespace.com/static/5628e080e4b040091d04af60/t/5afe9a71575d1f4238bef041/1526635122862/PRIVACY+POLICY.pdf"
                            target="_blank">Privacy Policy</a> | <a
                            href="https://static1.squarespace.com/static/5628e080e4b040091d04af60/t/633af29f41bce80f7be5d97a/1664807583382/Green+Policy.pdf"
                            target="_blank">Green Policy</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>