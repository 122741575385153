<script setup>
import { onBeforeMount, watchEffect, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useCivilCertificateStore } from "@/stores/civilCertificateStore";
import { useSalesOrderStore } from "@/stores/salesOrderStore";

const route = useRoute();
const router = useRouter();
const certificate = useCivilCertificateStore();
const salesOrderStore = useSalesOrderStore();
const progressPercentage = computed(() => {
    return parseInt((certificate.feedback.progress / certificate.requiredFields) * 100);
});

const checks = () => {
    certificate.checks();
};

const progressToOrder = async () => {
    addJob();
    let checks = await salesOrderStore.checkDetails();
    (salesOrderStore.jobs.length == 1 || checks !== true) ? router.push("/order-details") : router.push("/order-review");
};

const addAnotherJob = () => {
    addJob();
    router.push("/");
};

const addJob = () => {
    if (certificate.cert.id) {
        salesOrderStore.updateJob(certificate.cert);
    } else salesOrderStore.jobs.push(certificate.cert);
    certificate.cert = {};
}

watchEffect(() => {
    checks();
});

onBeforeMount(() => {
    if (route.params.id) {
        certificate.cert = salesOrderStore.getJob(route.params.id);
    }
    checks();
});
</script>
<template>
    <div class="container marriageCertificate mt-4">
        <h3>Civil Marriage Certificate</h3>
        <p v-if="certificate.feedback.message" class="p-2 text-white"
            :class="'bg-' + certificate.feedback.level">
            {{ certificate.feedback.message }}
        </p>
        <div class="progress">
            <div class="progress-bar" role="progressbar" aria-label="Progress"
                :style="'width: ' + progressPercentage + '%;'" :aria-valuenow="certificate.feedback.progress"
                aria-valuemin="0" aria-valuemax="100">
                {{ progressPercentage }}%
            </div>
        </div>
        <form>
            <label for="cert.year">Year formation was Registered</label>
            <fieldset>
                <input type="number" v-model="certificate.cert.year" id="cert.year" class="form-control">
                <i v-if="certificate.feedback.year" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.dob">Date of Formation</label>
            <fieldset>
                <input type="date" v-model="certificate.cert.dob" id="cert.dob" class="form-control">
                <i v-if="certificate.feedback.dob" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.place">Place of Formation</label>
            <fieldset>
                <input type="text" v-model="certificate.cert.place" id="cert.place" class="form-control">
                <i v-if="certificate.feedback.place" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.surname">Partner 1 Surname</label>
            <fieldset>
                <input type="text" v-model="certificate.cert.surname" id="cert.surname" class="form-control">
                <i v-if="certificate.feedback.surname" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.forenames">Partner 1 Forename(s)</label>
            <fieldset>
                <input type="text" v-model="certificate.cert.forenames" id="cert.forenames" class="form-control">
                <i v-if="certificate.feedback.forenames" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.partner1Address">Partner 1 Address (at time of formation)</label>
            <fieldset>
                <textarea id="cert.partner1Address" v-model="certificate.cert.partner1Address"
                    class="form-control"></textarea>
                <i v-if="certificate.feedback.partner1Address" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.party2Surname">Partner 2 Surname</label>
            <fieldset>
                <input type="text" v-model="certificate.cert.party2Surname" id="cert.party2Surname"
                    class="form-control">
                <i v-if="certificate.feedback.party2Surname" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.party2Forenames">Partner 2 Forename(s)</label>
            <fieldset>
                <input type="text" v-model="certificate.cert.party2Forenames" id="cert.party2Forenames"
                    class="form-control">
                <i v-if="certificate.feedback.party2Forenames" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="cert.partner2Address">Partner 2 Address (at time of formation)</label>
            <fieldset>
                <textarea id="cert.partner2Address" v-model="certificate.cert.partner2Address"
                    class="form-control"></textarea>
                <i v-if="certificate.feedback.partner2Address" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <div class="d-flex justify-content-between">
                <button class="btn btn-danger mt-2" :disabled="progressPercentage != 100"
                    @click.prevent="addAnotherJob">Another</button>

                <button class="btn btn-success mt-2" :disabled="progressPercentage != 100"
                    @click.prevent="progressToOrder">Next</button>
            </div>
        </form>
    </div>
</template>
<style scoped>
fieldset {
    position: relative;
    display: flex;
    align-items: center;
}

fieldset i {
    position: absolute;
    right: 10px;
    color: green;
    padding: 2px;
    font-size: 1.5em;
}
</style>