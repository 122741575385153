<template>
    <footer class="bg-dark">

    </footer>
</template>
<style>
footer {
    margin-top: 1em;
    min-height: 150px;
    color: white;
    padding: 2em;
}
</style>