import { defineStore } from "pinia";
import { useProfileStore } from "@/stores/profileStore";
// import { useCountryStore } from "@/stores/countryStore";

export const useSalesOrderStore = defineStore("salesOrderStore", {
    state: () => ({
        jobs: [],
        detail: {},
        charges: {},
        billing: {},
        feedback: {},
        proceedToReview: false,
        proceedToPay: true,
        services: [],
        priorities: [],
        returnOptions: [],
        feedbackAlert: null,
        fallbackDespatchID: 2,
        maritalStatuses: [
            { id: 1, name: 'Married' },
            { id: 2, name: 'Divorced' },
            { id: 3, name: 'Widowed' },
            { id: 4, name: 'Single' },
        ],
        billRecipient: false,
        detailsScore: 12,
    }),
    getters: {
        serviceName() {
            for (let s in this.services) {
                if (this.services[s].id == this.detail.serviceID) return this.services[s].name;
            }
        },
        jobReturnOption() {
            if (this.detail.recipientCountryID) {
                for (let r in this.returnOptions) {
                    if (this.returnOptions[r].countries.includes(this.detail.recipientCountryID)) return this.returnOptions[r];
                }
            }
            return this.returnOptions[1];
        },
        despatchName() {
            for (let r in this.returnOptions) {
                if (this.returnOptions[r].countries.includes(this.detail.recipientCountryID)) return {
                    name: this.returnOptions[r].name,
                    description: this.returnOptions[r].description
                }
            }
            return {
                name: this.returnOptions[1].name,
                description: this.returnOptions[1].description
            }
        },
    },
    actions: {
        getJob(jobID) {
            // const profileStore = useProfileStore();
            // const url = this.rootApi + "/guest/login";
            for (let j in this.jobs) {
                if (this.jobs[j].id == jobID) return this.jobs[j];
            }
        },
        updateJob(job) {
            for (let j in this.jobs) {
                if (this.jobs[j].id == job.id) this.jobs[j] == job;
            }
        },
        async addJob(job) {
            if (job.id) {
                for (let j in this.jobs) {
                    if (this.jobs[j].id == job.id) this.jobs[j] == job;
                }
            } else {
                this.jobs.push(job);
            }
            let result = await this.submitOrder();
            return result;
        },
        async deleteJob(jobID) {
            if (!jobID || !this.detail.hashVal) return;
            const profileStore = useProfileStore();
            const url = profileStore.rootApi + "/public-gro/job";
            const method = 'DELETE';
            const body = JSON.stringify({
                jobID,
                orderHash: this.detail.hashVal
            });
            let response = await fetch(url, {
                method,
                body
            });
            let data = await response.json();
            if (response.status == 200) {
                await this.fetchOrder();
                return true;
            }
            return data;
        },
        certificatePriority(certificatePriorityID) {
            for (let c in this.priorities.certificate) {
                if (this.priorities.certificate[c].id == certificatePriorityID) return this.priorities.certificate[c].name + " | " + this.priorities.certificate[c].description;
            }
        },
        apostillePriority(apostillePriorityID) {
            for (let a in this.priorities.apostille) {
                if (this.priorities.apostille[a].id == apostillePriorityID) return this.priorities.apostille[a].name + " | " + this.priorities.apostille[a].description;
            }
        },
        toggleBillingRecipient() {
            this.billRecipient = !this.billRecipient;
            if (this.billRecipient === true) {
                this.detail.billingName = this.detail.recipientName;
                this.detail.billingEmail = this.detail.recipientEmail;
                this.detail.billingTelephone = this.detail.recipientTelephone;
                this.detail.billingLine1 = this.detail.recipientLine1;
                this.detail.billingLine2 = this.detail.recipientLine2;
                this.detail.billingLine3 = this.detail.recipientLine3;
                this.detail.billingTown = this.detail.recipientTown;
                this.detail.billingCounty = this.detail.recipientCounty;
                this.detail.billingPostcode = this.detail.recipientPostcode;
                this.detail.billingCountryID = this.detail.recipientCountryID;
            }
        },
        async checkBilling() {
            this.feedbackAlert = null;
            if (this.billRecipient === true) {
                this.feedback.progress = 10;
                this.feedback.message = this.feedback.level = null;
                return;
            }
            this.feedback = {};
            this.feedback.progress = 0;
            if (!this.detail.billingName) {
                this.feedback.message = "Please enter your Name";
                this.feedback.level = "warning";
                return;
            }
            this.feedback.progress++;
            this.feedback.billingName = true;

            if (!this.detail.billingEmail) {
                this.feedback.message = "Please complete your Email address";
                this.feedback.level = "warning";
                return;
            } else if (!this.detail.billingEmail.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                this.feedbackAlert = 'billingEmail';
                this.feedback.message = "Please ensure your Email is correctly formatted";
                this.feedback.level = "warning";
                return;
            }
            this.feedback.progress++;
            this.feedback.billingEmail = true;

            if (!this.detail.billingTelephone) {
                this.feedback.message = "Please complete your telephone number";
                this.feedback.level = "warning";
                return;
            }
            this.feedback.progress++;
            this.feedback.billingTelephone = true;

            if (!this.detail.billingLine1) {
                this.feedback.message = "Please complete the first line of your address";
                this.feedback.level = "warning";
                return;
            }
            this.feedback.progress += 3;
            this.feedback.billingLine1 = true;
            this.feedback.billingLine2 = true;
            this.feedback.billingLine3 = true;

            if (!this.detail.billingTown) {
                this.feedback.message = "Please complete your address town";
                this.feedback.level = "warning";
                return;
            }
            this.feedback.progress++;
            this.feedback.billingTown = true;

            if (!this.detail.billingCounty) {
                this.feedback.message = "Please complete your address county";
                this.feedback.level = "warning";
                return;
            }
            this.feedback.progress++;
            this.feedback.billingCounty = true;

            if (!this.detail.billingPostcode) {
                this.feedback.message = "Please complete your address postcode";
                this.feedback.level = "warning";
                return;
            }
            this.feedback.progress++;
            this.feedback.billingPostcode = true;

            if (!this.detail.billingCountryID) {
                this.feedback.message = "Please complete your address country";
                this.feedback.level = "warning";
                return;
            }
            this.feedback.progress++;
            this.feedback.billingCountryID = true;

        },
        async checkDetails() {
            this.feedback = {};
            this.detailsScore = 12; // Set score for standard Cert.
            this.feedback.progress = 0;
            this.feedbackAlert = null;
            if (!this.detail.serviceID) {
                if (!this.feedback.message) this.feedback.message = "Please choose your service level";
                this.feedback.level = "warning";
            } else {
                this.feedback.progress++;
                this.feedback.serviceID = true;
            }

            if (!this.detail.certificatePriorityID) {
                if (!this.feedback.message) this.feedback.message = "Please choose your Certificate priority level";
                this.feedback.level = "warning";
            } else {
                this.feedback.progress++;
                this.feedback.certificatePriorityID = true;
            }

            if (this.detail.serviceID > 1) {
                this.detail.apostillePriorityID = 1;
                this.detailsScore = (this.detail.serviceID == 3) ? 14 : 13;
                // console.log(`Apostille Rqd, now require ${this.detailsScore} points`);
                if (this.detail.apostillePriorityID) {

                    this.feedback.progress++;
                    this.feedback.apostillePriorityID = true;
                } else {
                    if (!this.feedback.message) this.feedback.message = "Please choose your Apostille priority level";
                    this.feedback.level = "warning";
                }
            } else {
                this.feedback.apostillePriorityID = true;
            }

            if (this.detail.serviceID > 2 && !this.detail.legalisationEmbassyID) {
                if (!this.feedback.message) this.feedback.message = "Please choose the Embassy for legalisation";
                this.feedback.level = "warning";
            } else if (this.detail.serviceID > 2) {
                this.feedback.progress++;
                this.feedback.legalisationEmbassyID = true;
            }

            if (!this.detail.recipientCountryID) {
                if (!this.feedback.message) this.feedback.message = "Please select the destination Recipient's Country";
                this.feedback.level = "warning";
            } else {
                this.feedback.progress++;
                this.feedback.recipientCountryID = true;
            }

            if (!this.detail.recipientName) {
                if (!this.feedback.message) this.feedback.message = "Please complete Recipient's Name";
                this.feedback.level = "warning";
            } else {
                this.feedback.progress++;
                this.feedback.recipientName = true;
            }

            if (!this.detail.recipientEmail) {
                if (!this.feedback.message) this.feedback.message = "Please complete Recipient's Email address";
                this.feedback.level = "warning";
            } else if (!this.detail.recipientEmail.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )) {
                if (!this.feedback.message) this.feedback.message = "Please ensure Recipient's Email is correctly formatted";
                this.feedbackAlert = 'recipientEmail';
                this.feedback.level = "warning";
            } else {
                this.feedback.progress++;
                this.feedback.recipientEmail = true;
            }

            if (!this.detail.recipientTelephone) {
                if (!this.feedback.message) this.feedback.message = "Please complete Recipient's Telephone";
                this.feedback.level = "warning";
            } else {
                this.feedback.progress++;
                this.feedback.recipientTelephone = true;
            }

            if (!this.detail.recipientLine1) {
                if (!this.feedback.message) this.feedback.message = "Please complete the 1st line of the Recipient's address";
                this.feedback.level = "warning";
            } else {
                this.feedback.progress += 3;
                this.feedback.recipientLine1 = true;
                this.feedback.recipientLine2 = true;
                this.feedback.recipientLine3 = true;
            }

            if (!this.detail.recipientTown) {
                if (!this.feedback.message) this.feedback.message = "Please complete Recipient's town";
                this.feedback.level = "warning";
            } else {
                this.feedback.progress++;
                this.feedback.recipientTown = true;
            }

            if (!this.detail.recipientCounty) {
                if (!this.feedback.message) this.feedback.message = "Please complete Recipient's County";
                this.feedback.level = "warning";
            } else {
                this.feedback.progress++;
                this.feedback.recipientCounty = true;
            }

            if (!this.detail.recipientPostcode) {
                if (!this.feedback.message) this.feedback.message = "Please complete Recipient's Postcode";
                this.feedback.level = "warning";
            } else {
                this.feedback.progress++;
                this.feedback.recipientPostcode = true;
            }
            this.proceedToReview = (this.feedback.progress == this.detailsScore) ? true : false;

            return this.proceedToReview;
        },
        async getPricing() {
            const profileStore = useProfileStore();
            const url = profileStore.rootApi + "/public-gro/pricing";
            const method = 'GET';
            let response = await fetch(url, {
                method,
            });
            let data = await response.json();
            if (response.status == 200) {
                this.services = data.services;
                this.priorities = data.priorities;
                this.returnOptions = data.returnOptions;
                return true;
            }
        },
        async submitOrder($submitForPricing = false) {
            const profileStore = useProfileStore();
            let url = profileStore.rootApi + "/public-gro/order";
            let body = {
                detail: this.detail,
                jobs: this.jobs,
            };
            let method;
            if ($submitForPricing === true) {
                method = 'PUT';
            } else if (this.detail.id) {
                method = 'PATCH';
            } else method = 'POST';
            let response = await fetch(url, {
                method,
                body: JSON.stringify(body),
            });
            let data = await response.json();
            if (response.status == 200) {
                this.detail = data.detail;
                this.jobs = data.jobs;
                this.charges = data.charges;
                return true;
            } else {
                return data;
            }
        },
        async fetchOrder() {
            const profileStore = useProfileStore();
            const url = profileStore.rootApi + "/public-gro/order/" + this.detail.hashVal;
            let response = await fetch(url);
            let data = await response.json();
            if (response.status == 200) {
                this.detail = data.detail;
                this.jobs = data.jobs;
                this.charges = data.charges;
            }
        }
    },
});
