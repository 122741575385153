<script setup>
import { ref, onBeforeMount, watchEffect, computed } from "vue";
import { useRouter } from "vue-router";
import { useSalesOrderStore } from "@/stores/salesOrderStore";
import { useCountryStore } from "@/stores/countryStore";
const router = useRouter();
const salesOrderStore = useSalesOrderStore();
const countryStore = useCountryStore();
const despatchNote = ref(null);
const progressPercentage = computed(() => {
    return parseInt((salesOrderStore.feedback.progress / salesOrderStore.detailsScore) * 100);
});
const filteredCountries = computed(() => {
    let returnVal = [];
    countryStore.countries.forEach((country) => {
        (country.id == 210) ? returnVal.unshift(country) : returnVal.push(country);
    })
    return returnVal;
})
const notifyDespatchDetails = () => {
    despatchNote.value = null;
    if (!salesOrderStore.detail.recipientCountryID) return;
    let message = "Despatched to " + countryStore.countryName(salesOrderStore.detail.recipientCountryID) + " via ";
    for (let r in salesOrderStore.returnOptions) {
        if (salesOrderStore.returnOptions[r].countries.includes(salesOrderStore.detail.recipientCountryID)) return (despatchNote.value = message + salesOrderStore.returnOptions[r].description + " at £" + salesOrderStore.returnOptions[r].price);
    }
    for (let r in salesOrderStore.returnOptions) {
        if (salesOrderStore.returnOptions[r].id == salesOrderStore.fallbackDespatchID) return (despatchNote.value = message + salesOrderStore.returnOptions[r].description + " at £" + salesOrderStore.returnOptions[r].price);
    }
}
const addAnotherCeriticate = () => {
    router.push("/");
}
const progressToReview = () => router.push("/order-review");
const checks = () => salesOrderStore.checkDetails();
watchEffect(() => checks());
onBeforeMount(() => checks());
</script>
<template>
    <div class="container birthCertificate mt-4">
        <h3>Order Details</h3>
        <p v-if="salesOrderStore.feedback.message" class="p-2 text-white"
            :class="'bg-' + salesOrderStore.feedback.level">{{ salesOrderStore.feedback.message }}</p>
        <div class="progress">
            <div class="progress-bar" role="progressbar" aria-label="Example with label"
                :style="'width: ' + progressPercentage + '%;'" :aria-valuenow="salesOrderStore.feedback.progress"
                aria-valuemin="0" aria-valuemax="100">
                {{ progressPercentage }}%
            </div>
        </div>
        <form class="mt-2">
            <h5>Information for your Order</h5>
            <label for="detail.serviceID">Service</label>
            <fieldset>
                <select class="form-control" id="detail.serviceID" v-model="salesOrderStore.detail.serviceID">
                    <option v-for="service in salesOrderStore.services" :key="service.id" :value="service.id">{{
                        service.name
                    }}</option>
                </select>
                <i v-if="salesOrderStore.feedback.serviceID" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="detail.certificatePriorityID">Certificate Priority</label>
            <fieldset>
                <select class="form-control" id="detail.certificatePriorityID"
                    v-model="salesOrderStore.detail.certificatePriorityID">
                    <option v-for="priority in salesOrderStore.priorities.certificate" :key="priority.id"
                        :value="priority.id">{{ priority.name }} : {{ priority.description }} @ £{{
                            priority.price.inclusive.toFixed(2)
                        }}</option>
                </select>
                <i v-if="salesOrderStore.feedback.certificatePriorityID" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <div v-if="salesOrderStore.detail.serviceID && salesOrderStore.detail.serviceID > 1"
                class="apostillePriority">
                <!-- <label for="detail.apostillePriorityID">Apostille Priority</label>
                <fieldset>
                    <select class="form-control" id="detail.apostillePriorityID"
                        v-model="salesOrderStore.detail.apostillePriorityID">
                        <option v-for="priority in salesOrderStore.priorities.apostille" :key="priority.id"
                            :value="priority.id">{{ priority.name }} : {{ priority.description }} @ £{{
                                priority.price.inclusive.toFixed(2)
                            }}</option>
                    </select>
                    <i v-if="salesOrderStore.feedback.apostillePriorityID" class="bi bi-check-circle-fill"></i>
                </fieldset> -->

                <div v-if="salesOrderStore.detail.serviceID > 2" class="legalisationEmbassy">
                    <label for="detail.legalisationEmbassyID">Country for use in</label>
                    <fieldset>
                        <select v-model="salesOrderStore.detail.legalisationEmbassyID" id="detail.legalisationEmbassyID"
                            class="form-control">
                            <option v-for="country in countryStore.legalisationCountries" :value="country.id"
                                :key="country.id">{{ country.name }}</option>
                        </select>
                        <i v-if="salesOrderStore.feedback.legalisationEmbassyID" class="bi bi-check-circle-fill"></i>
                    </fieldset>
                </div>
            </div>

            <hr>
            <h5>Recipient Delivery Information</h5>
            <label for="detail.recipientCountryID">Country</label>
            <fieldset>
                <select v-model="salesOrderStore.detail.recipientCountryID" id="detail.recipientCountryID"
                    class="form-control" @change="notifyDespatchDetails">
                    <option v-for="country in filteredCountries" :value="country.id" :key="country.id">{{
                        country.name
                    }}</option>
                </select>
                <i v-if="salesOrderStore.feedback.recipientCountryID" class="bi bi-check-circle-fill"></i>
            </fieldset>
            <p v-if="despatchNote" class="p-2 mt-2 bg-info">{{ despatchNote }}</p>

            <label for="detail.recipientName">Recipient Name</label>
            <fieldset>
                <input type="text" v-model="salesOrderStore.detail.recipientName" id="detail.recipientName"
                    class="form-control" placeholder="Contact Name" required autocomplete="name" />
                <i v-if="salesOrderStore.feedback.recipientName" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="detail.recipientEmail">Recipient Email</label>
            <fieldset>
                <input type="email" v-model="salesOrderStore.detail.recipientEmail" id="detail.recipientEmail"
                    class="form-control"
                    :class="{ 'border-danger': (salesOrderStore.feedbackAlert == 'recipientEmail') }"
                    placeholder="Contact Email" required
                    autocomplete="email" />
                <i v-if="salesOrderStore.feedback.recipientEmail" class="bi bi-check-circle-fill"></i>
                <i v-else-if="salesOrderStore.feedbackAlert == 'recipientEmail'"
                    class="bi bi-exclamation-triangle-fill text-danger"></i>
            </fieldset>

            <label for="detail.recipientTelephone">Recipient Tel</label>
            <fieldset>
                <input type="tel" v-model="salesOrderStore.detail.recipientTelephone" id="detail.recipientTelephone"
                    class="form-control" placeholder="Contact Telephone" required />
                <i v-if="salesOrderStore.feedback.recipientTelephone" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="detail.recipientLine1">Address Line 1</label>
            <fieldset>
                <input type="text" v-model="salesOrderStore.detail.recipientLine1" id="detail.recipientLine1"
                    class="form-control" placeholder="Contact Address Line 1" required autocomplete="address-line1" />
                <i v-if="salesOrderStore.feedback.recipientLine1" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="detail.recipientLine2">Address Line 2</label>
            <fieldset>
                <input type="text" v-model="salesOrderStore.detail.recipientLine2" id="detail.line2"
                    class="form-control" placeholder="Contact Address Line 2" autocomplete="address-line2" />
                <i v-if="salesOrderStore.feedback.recipientLine2" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="detail.recipientLine3">Address Line 3</label>
            <fieldset>
                <input type="text" v-model="salesOrderStore.detail.recipientLine3" id="detail.line3"
                    class="form-control" placeholder="Address Line 3" autocomplete="address-line3" />
                <i v-if="salesOrderStore.feedback.recipientLine3" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="detail.recipientTown">Town</label>
            <fieldset>
                <input type="text" v-model="salesOrderStore.detail.recipientTown" id="detail.recipientTown"
                    class="form-control" placeholder="Town" required autocomplete="address-level2" />
                <i v-if="salesOrderStore.feedback.recipientTown" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="detail.recipientCounty">County</label>
            <fieldset>
                <input type="text" v-model="salesOrderStore.detail.recipientCounty" id="detail.recipientCounty"
                    class="form-control" placeholder="County" autocomplete="address-level3" />
                <i v-if="salesOrderStore.feedback.recipientCounty" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <label for="detail.recipientPostcode">Postcode</label>
            <fieldset>
                <input type="text" v-model="salesOrderStore.detail.recipientPostcode" id="detail.recipientPostcode"
                    class="form-control" placeholder="Postcode" autocomplete="postal-code" />
                <i v-if="salesOrderStore.feedback.recipientPostcode" class="bi bi-check-circle-fill"></i>
            </fieldset>

            <div class="d-flex justify-content-between">
                <button class="btn btn-warning mt-2" :disabled="progressPercentage !== 100"
                    @click.prevent="addAnotherCeriticate">Add Another</button>
                <button class="btn btn-success mt-2" :disabled="progressPercentage !== 100"
                    @click.prevent="progressToReview">Next</button>
            </div>

        </form>

    </div>
</template>
<style scoped>
fieldset {
    position: relative;
    display: flex;
    align-items: center;
}

fieldset i {
    position: absolute;
    right: 10px;
    color: green;
    padding: 2px;
    font-size: 1.5em;
}

h5 {
    background-color: #444;
    color: white;
    padding: .5em;
}
</style>
